import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "styles/responsive";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #2D3142;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Wrapper = styled.div`
  width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 20px 22px 6px -13px rgba(0,0,0,0.1);
  ${mobile({ width: "80%" })}
`;
export const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Input = styled.input`
  flex: 1;
  min-width: 40%;
  padding: 10px;
  border-radius: ${(props) => props.theme.border.r10};
  border: none;
  outline: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
export const Button = styled.button`
  width: 100%;
  border: none;
  padding: 15px 20px;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  margin: 10px 0 5px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: ${(props) => props.theme.border.r10};
  cursor: pointer;
  &:disabled {
    color: green;
    cursor: not-allowed;
  }
`;
export const ForgotPass = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: end;
`;
export const Register = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;
export const Desc = styled.span`
  text-decoration: none;
  color: #000;
  margin-right: 5px;
`;
export const Links = styled(Link)`
  margin: 5px 0px;
  text-decoration: underline;
  cursor: pointer;
`;
export const Alerta = styled.div`
  color: red;
  font-size: 12px;
  margin-left: 10px;
`;
export const BoxInput = styled.div`
  display: flex;
  border: 2px solid #eee;
  border-radius: ${(props) => props.theme.border.r10};
  margin-top: 10px;
`;
export const Icon = styled.i`
  color: gray;
  display: flex;
  align-items: center;
  margin: 0 5px;
`;
export const IconPass = styled.i`
  color: gray;
  display: flex;
  align-items: center;
  margin: 0 5px;
  cursor: pointer;
`;