import { createSlice } from '@reduxjs/toolkit';

export const infoSlice = createSlice({
    name: "info",
    initialState: {
        tienda: [],
        nosotros: [],
        isFetching: false,
        error: false,
    },
    reducers: {
        // ------------------ OBTENER INFORMACION TIENDA ------------------
        getInfoStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getInfoSuccess: (state, action) => {
            state.isFetching = false;
            state.tienda = action.payload;
        },
        getInfoFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        // ------------------ OBTENER INFORMACION NOSOTROS ------------------
        getInfoNosotrosStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getInfoNosotrosSuccess: (state, action) => {
            state.isFetching = false;
            state.nosotros = action.payload;
        },
        getInfoNosotrosFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getInfoStart, getInfoSuccess, getInfoFailure, getInfoNosotrosStart, getInfoNosotrosSuccess, getInfoNosotrosFailure } = infoSlice.actions;
export default infoSlice.reducer;