import styled from "styled-components";
import { mobile } from "../responsive";

export const Wrapper = styled.div`
  flex: 4;
  padding: 10px;
  background: ${props => props.theme.colors.white};
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;
export const Title = styled.h1`
  ${mobile({ fontSize: "24px" })}
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
`;
export const BoxInput = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  ${mobile({ width: "295px" })}
`;
export const Label = styled.label`
  margin-bottom: 5px;
  color: ${(props) => props.theme.colors.gray3};
  font-weight: 600;
`;
export const Input = styled.input`
  width: ${(props) => props.w ? props.w : `100%` };
  margin: ${(props) => props.margin ? props.margin : null};
  padding: 10px;
  
  border: 1px solid ${(props) => props.theme.colors.gray4};
  outline-color: ${(props) => props.theme.colors.outline};
  border-radius: 4px;
`;
export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  height: 70px;
  outline-color: ${(props) => props.theme.colors.outline};
`;
export const Select = styled.select`
  width: 65%;
  padding: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray4};
  outline-color: ${(props) => props.theme.colors.outline};
  border-radius: 4px;
`;
export const BoxButtons = styled.div`
  display: flex;
  margin-top: 10px;
`;
export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px;
  color: #444;
`;
export const Span = styled.span`
    font-size: 15px;
    margin-left: 5px;
`;


export const TableContainer = styled.div`
  display: flex;
  height: 100%;
`;
export const Table = styled.div`
  flex-grow: 1;
`;
export const Image = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
`;