import styled from "styled-components";

export const Button = styled.button`
 
  width: ${(props) => props.w ? props.w : null };

  padding: ${(props) => props.padding ? props.padding : null};
  padding-top: ${(props) => props.pt};
  padding-right: ${(props) => props.pr};
  padding-bottom: ${(props) => props.pb};
  padding-left: ${(props) => props.pl};

  margin: ${(props) => props.margin ? props.margin : null};
  margin-top: ${(props) => props.mt};
  margin-right: ${(props) => props.mr};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};

  border: ${(props) => props.border || `none`};;
  border-radius: ${(props) => props.br || `4px`};

  background-color: ${(props) => (props.bgColor === "primary" ? `${props.theme.colors.btnPrimary}` : null || props.bgColor === "secondary" ? `${props.theme.colors.btnSecondary}` : null || props.bgColor === "alternative" ? `${props.theme.colors.btnAlternative}` : null || props.bgColor === "cancel" ? `${props.theme.colors.btnCancel}` : null || props.bgColor ? props.bgColor : `${props.theme.colors.btnPrimary}`)};
  color: ${(props) => props.color};

  font-size: ${(props) => props.fs ? props.fs : `14px` };
  font-weight: ${(props) => props.fw ? props.fw : 600 };

  display: grid;
  place-items: center;

  cursor: pointer;
`;
