import axios from 'axios';

const BASE_URL_API = process.env.REACT_APP_API_URL_DEV;

const user = JSON.parse(localStorage.getItem('persist:root'))?.user;
const currentUser = user && JSON.parse(user).currentUser;
const TOKEN = currentUser?.accessToken;

export const publicRequest = axios.create({
  baseURL: BASE_URL_API,
});

export const userRequest = axios.create({
  baseURL: BASE_URL_API,
  headers: { token: `${TOKEN}` },
});
