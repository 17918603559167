import { createSlice } from '@reduxjs/toolkit';

export const bannerSlice = createSlice({
    name: "banner",
    initialState: {
        listImg: [],
        isFetching: false,
        error: false,
    },
    reducers: {
        // ------------------ OBTENER IMAGENES BANNER ------------------
        getBannerStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getBannerSuccess: (state, action) => {
            state.isFetching = false;
            state.listImg = action.payload;
        },
        getBannerFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getBannerStart, getBannerSuccess, getBannerFailure } = bannerSlice.actions;
export default bannerSlice.reducer;