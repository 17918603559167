import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { userRequest } from 'config/clienteAxios';
import { currencyFormat } from 'utils/data-conversion';
import { mobile } from 'styles/responsive';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  div {
    flex: 1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    border-left: 5px solid #555;

    ${mobile({ width: '72%', marginBottom: '10px' })}

    h3 {
      font-size: 20px;
    }

    span {
      display: inline-block;
      margin: 10px 0;
      font-size: 30px;
      font-weight: 600;
    }
  }
  ${mobile({ flexDirection: 'column' })}
`;

const FeaturedInfo = () => {
  const [total, setTotal] = useState(0);
  const [numVentas, setNumVentas] = useState(0);
  const [pendiente, setPendiente] = useState(0);
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  useEffect(() => {
    const getIncome = async () => {
      try {
        const res = await userRequest.get('/ordenes/ingreso/ventas', {
          headers: { token: accessToken },
        });

        const { totalIngresos, numVentas, ordenesPendientes } = res.data;
        setTotal(totalIngresos);
        setNumVentas(numVentas);
        setPendiente(ordenesPendientes);
      } catch (error) {}
    };
    getIncome();
  }, [accessToken]);

  return (
    <Container>
      <div>
        <h3>Ingresos</h3>
        <span>{total > 0 ? currencyFormat(total) : currencyFormat(total)}</span>
        <p>Ingresos totales</p>
      </div>
      <div>
        <h3>Ventas</h3>
        <span>{numVentas > 0 ? numVentas : '0'}</span>
        <p>Cantidad de ventas</p>
      </div>
      <div>
        <h3>Ventas (Pendientes)</h3>
        <span>{pendiente > 0 ? pendiente : '0'}</span>
        <p>Ventas pendientes</p>
      </div>
    </Container>
  );
};

export default FeaturedInfo;
