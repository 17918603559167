import React from "react";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";

import styled from "styled-components";
import { mobile } from "styles/responsive";

const Container = styled.div``;
const Wrapper = styled.div`
  margin-top: 80px;
  padding: 0 50px;
  margin-bottom: 100px;
  ${mobile({ padding: "0 20px"})};
`;
const Static = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
`;
const Title = styled.h1`
  font-weight: 200;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 50px;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 25px;
  }
`;
const Subtitle = styled.h4`
  margin: 20px 0px;
  color: #000;
`;
const Desc = styled.p`
  margin: 20px 0px;
  color: #000;
`;

const Devoluciones = () => {
  return (
    <Container>
      <Static>
        <Header />
      </Static>
      <Wrapper>
        <Title>POLÍTICA DE DEVOLUCIONES</Title>
        <Desc>
          Si usted está contratando como consumidor y usuario, tiene usted
          derecho a desistir del presente contrato en un plazo de 14 días
          naturales sin necesidad de justificación. El plazo de desistimiento
          expirará a los 14 días naturales del día que usted o un tercero por
          usted indicado, distinto del transportista, adquirió la posesión
          material de los bienes o en caso de que los bienes que componen su
          pedido se entreguen por separado, a los 14 días naturales del día que
          usted o un tercero por usted indicado, distinto del transportista,
          adquirió la posesión material del último de esos bienes. Para ejercer
          el derecho de desistimiento, deberá usted notificarnos,
          a la dirección, (aqui va la dirección). Para cumplir
          el plazo de desistimiento, basta con que la comunicación relativa al
          ejercicio por su parte de este derecho sea enviada antes de que venza
          el plazo correspondiente.
        </Desc>
        <Subtitle>
          Devoluciones de productos defectuosos
        </Subtitle>
        <Desc>
          En los casos en que usted considere que en el momento de la entrega el
          producto no se ajusta a lo estipulado en el contrato, deberá ponerse
          en contacto con nosotros de forma inmediata nada más reciba el pedido
          (máximo 24 horas desde su recepción) por medio de nuestro formulario
          de contacto facilitando los datos del producto, así como del daño que
          sufre, o bien llamando por teléfono al número de <strong>(aqui va el teléfono)</strong> donde le
          indicaremos la forma de proceder. Procederemos a examinar
          detenidamente el producto devuelto y le comunicaremos por Email
          dentro de un plazo razonable si procede el reembolso o la sustitución
          del mismo (en su caso). El reembolso o la sustitución del artículo se
          efectuarán lo antes posible y, en cualquier caso, dentro de los 14
          días siguientes a la fecha en la que le enviemos un correo electrónico
          confirmando que procede el reembolso o la sustitución del artículo no
          conforme. Las cantidades pagadas por aquellos productos que sean
          devueltos a causa de alguna tara o defecto, cuando realmente exista,
          le serán reembolsadas íntegramente, incluidos los gastos de entrega
          incurridos para entregarle el artículo y los costes en que usted
          hubiera incurrido para devolvérnoslo a nosotros. La devolución se
          efectuará en el mismo medio de pago que se utilizó para pagar la
          compra, salvo cuando para la devolución se presente un ticket regalo.
          En este último caso, el reembolso se realizará mediante una tarjeta o
          un ticket abono. Quedan en todo caso a salvo los derechos reconocidos
          por la legislación vigente.
        </Desc>
      </Wrapper>
      <Footer />
    </Container>
  );
};

export default Devoluciones;
