import { useEffect } from "react";

import styled from "styled-components";
import { mobile, mobileLg } from "styles/responsive";
import MercadoPago from "assets/images/mercadopago-footer.png";
import { Link } from "react-router-dom";
import {
  Facebook,
  Instagram,
  MailOutline,
  Phone,
  Room,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { getInfo } from "api/apiCalls";

const Container = styled.div`
  display: flex;
  /* margin-top: 20px; */
  background-color: ${props => props.theme.footer.bgColor};
  ${mobile({ flexDirection: "column" })}
  ${mobileLg({ flexDirection: "column" })}
`;
const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
const Logo = styled.h1`
  color: ${props => props.theme.footer.textColor};
`;
const Desc = styled.p`
  margin: 20px 0px;
  color: ${props => props.theme.footer.textColor};
`;
const SocialContainer = styled.div`
  display: flex;
  color: ${props => props.theme.footer.textColor};
`;
const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.gray1};
  color: ${props => props.theme.footer.textColor};
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Center = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  /* ${mobile({ display: "none" })} */
`;
const Title = styled.h1`
  font-size: 18px;
  margin-bottom: 30px;
  color: ${props => props.theme.footer.textColor};
`;
const List = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: ${props => props.theme.footer.textColor};
`;
const ListItem = styled.p`
  color: ${props => props.theme.footer.textColor};
  margin-bottom: 10px;
  /* ${mobile({ width: "100%" })} */
`;
const Right = styled.div`
  flex: 1;
  padding: 20px;
  /* background-color: ${props => props.theme.colors.gray}; */
  /* ${mobile({ backgroundColor: "#eee" })} */
`;
const ContactItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.footer.textColor};
`;
const ContactItemPayment = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.footer.textColor};
  ${mobileLg({ justifyContent: "center" })}
`;
const Payment = styled.img`
  width: 300px;
  height: 106.84px;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background-color: ${props => props.theme.footer.bgColor};
  color: ${props => props.theme.footer.textColor};
  ${mobile({ textAlign: "center", fontSize: "12px" })}
`;
const Enlace = styled.a`
  text-decoration: none;
  color: ${props => props.theme.footer.textColor};
  &:hover {
    text-decoration: underline;
  }
`;
const Div1 = styled.div`
  width: 50%;
  ${mobile({ display: "none" })}
`;
const Div2 = styled.div`
  width: 50%;
  ${mobile({ width: "100%" })}
`;
const Button = styled.button`
  padding: 10px;
  background-color: #eee;
  border: 1px solid #000;
  cursor: pointer;
`;

const Footer = () => {

  const info = useSelector((state) => state.info.tienda);
  const dispatch = useDispatch();

  useEffect(() => {
    getInfo(dispatch);
  }, [dispatch]);
  
  return (
    <>
      <Container>
        <Left>
          <Logo>{info?.name || "Mitienda."}</Logo>
          <Desc>{info?.desc || "Informacion de la tienda."}</Desc>
          <SocialContainer>
            <strong style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
              Siguenos en:
            </strong>
            <a
              href={info?.facebook || "https://www.facebook.com/"}
              target="_blank"
              rel="noopener noreferrer"
              title="Facebook"
            >
              <SocialIcon>
                <Facebook style={{ fontSize: "30px", color: "inherit" }} />
              </SocialIcon>
            </a>
            <a
              href={info?.instagram || "https://www.instagram.com/"}
              target="_blank"
              rel="noopener noreferrer"
              title="Instagram"
            >
              <SocialIcon>
                <Instagram style={{ fontSize: "30px", color: "inherit" }} />
              </SocialIcon>
            </a>
          </SocialContainer>
        </Left>
        <Center>
          <Div1>
            <Title>Navegación</Title>
            <List>
              <Link to="/" style={{ color: "inherit", textDecoration: "underline" }}>
                <ListItem>Inicio</ListItem>
              </Link>
              <Link to="#!" style={{ color: "inherit", textDecoration: "underline" }}>
                <ListItem>Categorias</ListItem>
              </Link>
              <Link to="/lista-productos" style={{ color: "inherit", textDecoration: "underline" }}>
                <ListItem>Productos</ListItem>
              </Link>
              <Link to="/nosotros" style={{ color: "inherit", textDecoration: "underline" }}>
                <ListItem>Nosotros</ListItem>
              </Link>
              <Link to="/contacto" style={{ color: "inherit", textDecoration: "underline" }}>
                <ListItem>Contacto</ListItem>
              </Link>
            </List>
          </Div1>
          <Div2>
            <Title>Guia de Compras</Title>
            <List>
              <Link to="/envio" style={{ color: "inherit", textDecoration: "underline" }}>
                <ListItem>Envío</ListItem>
              </Link>
              <Link to="/devoluciones" style={{ color: "inherit", textDecoration: "underline" }}>
                <ListItem>Devoluciones y cambios</ListItem>
              </Link>
              <Link to="/boton-arrepentimiento" style={{ color: "inherit", textDecoration: "underline" }}>
                <Button>Botón de arrepentimiento</Button>
              </Link>
            </List>
          </Div2>
        </Center>
        <Right>
          <Title>Contacto</Title>
          <ContactItem>
            <Room style={{ marginRight: "10px" }} />{" "}
            <span style={{ color: "inherit" }}>{info?.address || "Ubicacion de la tienda"}</span>
          </ContactItem>
          <ContactItem>
            <Phone style={{ marginRight: "10px" }} /> 
            <span style={{ color: "inherit" }}>+{info?.tel || "Teléfono"}</span>
          </ContactItem>
          <ContactItem>
            <MailOutline style={{ marginRight: "10px" }} />{" "}
            <span style={{ color: "inherit" }}>{info?.email || "Email"}</span>
          </ContactItem>
          <ContactItemPayment>
            <Payment src={MercadoPago} alt="Mercado pago" />
          </ContactItemPayment>
        </Right>
      </Container>
      <Bottom>
        &copy; <span style={{ marginRight: "3px" }}>2022</span>
        <Enlace href="https://ciatware.com" rel="noopener noreferrer" target="_blank">
          CiatWare
        </Enlace>
        . Todos los derechos reservados.
      </Bottom>
    </>
  );
};

export default Footer;
