import { createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from 'config/clienteAxios';

const accessToken = JSON.parse(localStorage.getItem('token_user'));

export const deleteProduct = createAsyncThunk(
  'product/delete',
  async (id, { rejectWithValue }) => {
    try {
      const productId = await userRequest.delete(`/productos/${id}`, {
        headers: { token: accessToken },
      });
      return productId.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addProduct = createAsyncThunk(
  'product/create',
  async (product, { rejectWithValue }) => {
    try {
      const res = await userRequest.post(`/productos`, product, {
        headers: { token: accessToken },
      });

      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
