import styled from "styled-components";
import { mobile, mobileLg, mobileMd } from "styles/responsive";

export const Wrapper = styled.div`
  margin-top: 70px;
  padding: 50px;
  display: flex;
  background-color: ${props => props.theme.colors.white};
  ${ mobileMd({padding: "10px", flexDirection: "column"}) }
`;
export const ImgContainer = styled.div`
  flex: 1;
`;
export const ImageBig = styled.img`
  width: 100%;
  height: 50vh;
  object-fit: contain;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid #e6e6e6;
  cursor: pointer;
  ${ mobile({height: "35vh"}) }
`;
export const ImageSmall = styled.img`
  width: 100%;
  height: 15vh;
  object-fit: contain;
  border: 1px solid #e6e6e6;
  background-color: ${props => props.theme.colors.white};
  cursor: pointer;
  &:hover {
    border: 2px solid ${(props) => props.theme.colors.primary};
  }
  ${ mobile({height: "10vh"}) }
`;
export const InfoContainer = styled.div`
  flex: 1;
  padding: 0 0 0 50px;
  ${ mobile({padding: "10px"}) }
  ${ mobileMd({padding: "0 10px"}) }
`;
export const Title = styled.h1`
  font-weight: 600;
`;
export const Desc = styled.p`
  margin: 10px 0px;
`;
export const P = styled.p`
  margin: 10px 0 0;
`;
export const Price = styled.span`
  font-size: 30px;
  color: ${(props) => props.theme.colors.black};
  font-weight: bold;
`;
export const FilterContainer = styled.div`
  width: 80%;
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  ${ mobileLg({width: "100%", flexWrap: "nowrap", gap: "10px"}) }
  ${ mobile({width: "100%"}) }
`;
export const Filter = styled.div`
  display: flex;
  align-items: center;
`;
export const FilterTitle = styled.span`
  font-size: 18px;
  font-weight: 400;
  margin-right: 10px;
  color: ${props => props.theme.colors.black};
  ${ mobileLg({fontSize: "16px"}) }
`;
export const FilterSize = styled.select`
  padding: 8px;
  border-radius: 10px;
`;
export const FilterSizeOption = styled.option`
`;
export const AddContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  ${ mobileLg({ width: "100%" }) }
  ${ mobile({width: "100%", marginTop: '20px'}) }
`;
export const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
`;
export const Amount = styled.span`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background-color: #f7f7f7;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 5px;
`;
export const Button = styled.button`
  padding: 15px;
  border: none;
  border-radius: ${(props) => props.theme.border.r10};
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: ${(props) => props.theme.colors.primary};

  ${ mobileLg({marginTop: "10px" }) }
  ${ mobileMd({marginTop: "0" }) }
`;
export const ButtonFav = styled.button`
  margin-left: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
`;
export const BoxImages = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
`;
export const Del = styled.del`
  font-size: 20px;
  color: #8d8d8d;
`;

export const Box = styled.div`
  padding: 50px 50px 0;
  ${ mobile({width: "100%", padding: '0'}) }
`;
export const Title2 = styled.h1`
  font-weight: bolder;
  ${ mobile({fontSize: "25px", padding: '10px'}) }
`;