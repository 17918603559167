import React, { useEffect, useState } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { alertSuccess } from 'components/alerts/SweetAlert';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  BoxButtons,
  BoxInput,
  Form,
  Label,
  Select,
  Title,
  TitleContainer,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { userRequest } from 'config/clienteAxios';

const OrderEdit = () => {
  const location = useLocation();
  const ordenId = location.pathname.split('/')[2];
  const history = useHistory();
  const [orden, setOrden] = useState({});
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  const handleInputChange = (e) => {
    setOrden((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await userRequest.put(`/ordenes/${ordenId}`, orden, {
        headers: { token: accessToken },
      });
      console.log(data);
      alertSuccess('¡Estado actualizado!');
      history.push('/ordenes');
    } catch (err) {}
  };

  useEffect(() => {
    const getOrderId = async () => {
      try {
        const res = await userRequest.get(`/ordenes/${ordenId}`, {
          headers: { token: accessToken },
        });
        console.log(res);
        setOrden(res.data);
      } catch (error) {}
    };
    getOrderId();
  }, [ordenId]);

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <TitleContainer>
            <Title>Editar Orden</Title>
          </TitleContainer>
          <Form>
            <BoxInput>
              <Label>Estado Orden</Label>
              <Select
                name='status'
                value={orden.status}
                onChange={handleInputChange}
              >
                <option value='Pendiente'>Pendiente</option>
                <option value='En preparación'>En preparación</option>
                <option value='Anulado'>Anulado</option>
                <option value='Preparado'>Preparado</option>
                <option value='Enviado'>Enviado</option>
              </Select>
            </BoxInput>
            <BoxButtons>
              <Button
                color='#fff'
                padding='10px 15px'
                margin='10px 10px 0 0'
                onClick={handleFormSubmit}
              >
                Actualizar
              </Button>
              <Link to='/ordenes' className='link'>
                <Button
                  bgColor='cancel'
                  color='#fff'
                  padding='10px 15px'
                  margin='10px 10px 0 0'
                >
                  Cancelar
                </Button>
              </Link>
            </BoxButtons>
          </Form>
        </Wrapper>
      </div>
    </>
  );
};

export default OrderEdit;
