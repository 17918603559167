import React from "react";

import Carrousel from "components/carrousel/banner/Carrousel";
import CategoriesList from "components/carrousel/categories/CategoriesList";
import Products from "components/product/Products";
import Services from "components/services/Services";
import Footer from "components/footer/Footer";

import styled from "styled-components";
import { mobile } from "styles/responsive";
import { Button } from "styles/GlobalComponents/ButtonStyles";
import { Link } from "react-router-dom";
import Layout from "components/layouts/Layout";

const Box = styled.div`
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Title = styled.h1`
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  position: relative;
  ${mobile({ fontSize: "30px" })}
`;

const Div = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const Home = () => {
  return (
    <Layout title='Home' desc='Este es el home de la tienda Pure'>
      <Carrousel />
      <Services />
      <Box>
        <Title>Categorias</Title>
      </Box>
      <CategoriesList />
      <Box>
        <Title>Productos</Title>
      </Box>
      <Products />
      <Div>
        <Link to="/lista-productos">
          <Button
            padding="15px"
            bgColor="transparent"
            border="2px solid #000"
            color="#000"
            fs="16px"
          >
            Ver Productos
          </Button>
        </Link>
      </Div>
      <Footer />
    </Layout>
  );
};

export default Home;
