import React from "react";

import styled from "styled-components";
import { AccessTime, AssignmentTurnedIn, HowToReg } from "@material-ui/icons";

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 20px 20px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
const Item = styled.div`
  width: 100%;
  height: 100px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.cl || props.theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.theme.border.r4};
  margin: 10px;

  /* box-shadow: 0 5px 12px -1px rgb(0 0 0 / 3%); */
  /* box-shadow: 0 30px 33px -41px rgb(0 0 0 / 24%); */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin: 10px 0;
  }
`;
const P = styled.p`
  margin-left: 10px;
  text-align: center;
  color: ${(props) => props.theme.colors.black};
`;

const Services = () => {
  // const color = useSelector((state) => state.info?.tienda?.colors[0]);
  // cl={color?.colorPrimary}

  return (
    <Container>
      <Item>
        <HowToReg style={{ fontSize: "40px" }} />
        <div>
          <P>Atención personalizada</P>
        </div>
      </Item>
      <Item>
        <AssignmentTurnedIn style={{ fontSize: "40px" }} />
        <div>
          <P>Productos de calidad</P>
        </div>
      </Item>
      <Item>
        <AccessTime style={{ fontSize: "40px" }} />
        <div>
          <P>Abierto toda la semana</P>
          <P>8AM-14PM</P>
        </div>
      </Item>
    </Container>
  );
};

export default Services;
