import styled from "styled-components";
import { mobile } from "styles/responsive";

export const Container = styled.div`
  height: 100vh;
  background-color: #ebf0f5;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 40px;
  box-shadow: 0 2px 3px #C8D0D8;
  ${mobile({ padding: "30px", width: "300px" })}
`;
export const Div = styled.div`
  width: 200px;
  height: 200px;
  background-color: #f8faf5;
  border-radius: 200px;

  i {
    color: #9abc66;
    font-size: 100px;
    line-height: 200px;
    margin-left: 50px;
    
  }
`;
export const Title = styled.h1`
  color: #88b04b;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
  ${mobile({ fontSize: "30px" })}
`;
export const P = styled.p`
  width: 600px;
  color: #404f5e;
  font-size: 20px;
  text-align: center;
  ${mobile({ width: "350px", fontSize: "18px" })}
`;
export const Btn = styled.button`
  padding: 10px 15px;
  border: 1px solid #404f5e;
  border-radius: 10px;
  color: #404f5e;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
`;