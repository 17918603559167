// Convertir la primera letra de un String en mayuscula
export function PrimeraLetraMayus(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Dar formato al input tipo date en Cupones => '20/05/2022'
export function Format(data) {
  console.log(data);
  return data.substring(0, 10).split('-').reverse().join('/');
}

// Formato de moneda
export const currencyFormat = (value) => {
  const opciones = { style: 'currency', currency: 'ARS' };
  return new Intl.NumberFormat('es-AR', opciones).format(value);
};
