import styled from "styled-components";
import { mobile } from "styles/responsive";

export const Container2 = styled.div`
  display: flex;
`;
export const Box1 = styled.div`
  flex: 1;
  box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.35);

  & > div {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    margin: 10px 0;

    & div {
      width: 100%;
      height: 250px;
      overflow: auto;
    }

    & p {
      width: 200px;
      font-size: 16px;
      margin-left: 10px;
      cursor: pointer;

      & input {
        accent-color: ${(props) => props.theme.colors.accent};
      }
      & label {
        cursor: pointer;
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
export const Box2 = styled.div`
  flex: 4;
`;
export const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* display: grid;
  grid-template-columns: repeat(4, 350px);
  gap: 10px; */
`;
export const Box = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
export const Title = styled.h1`
  margin-top: 70px;
  font-weight: 500;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 60px;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 25px;
  }
`;
export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
export const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 30px 10px;
`;
export const FilterBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 20px 10px;
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;
export const BtnFilter = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #000;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 360px;
  }
`;
export const SearchBox = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 24px;
  padding: 0 10px;

  &:hover {
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    border-color: rgba(223, 225, 229, 0);
  }
  ${mobile({ width: "100%" })}
`;
export const Input = styled.input`
  border: none;
  background-color: transparent;
  padding: 10px;
  border-radius: 24px;
  outline: none;
  width: 300px;
`;
export const Filter = styled.div`
  margin: 20px;
  display: none;

  @media (max-width: 768px) {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
  }
`;
export const FilterText = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
  ${mobile({ marginRight: "0", fontSize: "16px" })}
`;
export const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
  border-radius: 10px;
  ${mobile({ margin: "0", padding: "5px 10px" })}
`;
export const Option = styled.option``;