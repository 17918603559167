import React, { useEffect } from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";
import { mobile } from "styles/responsive";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import 'swiper/modules/effect-fade/effect-fade';
import "swiper/modules/navigation/navigation";
import "swiper/modules/pagination/pagination";

import "../banner/carrousel.css";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "api/apiCalls";

const Container = styled.div`
  margin: -65px 25px 0px;
  background-color: transparent;

`;
const Box = styled.div`
  width: 98%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.border};
  position: relative;
  border-radius: ${(props) => props.theme.border.r4};
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  ${mobile({ width: "95%", cursor: "none"})};
`;
const Title = styled.h1`
  text-align: center;
  font-size: 22px;
  z-index: 1;
  letter-spacing: 2px;
  background-color: white;
  padding: 8px 20px;
`;
const Button = styled.button`
  padding: 10px 15px;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: ${(props) => props.theme.border.r4};
  text-decoration: none;
`;
const Links = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  z-index: 1;
`;

const CategoriesList = () => {

  const categories = useSelector((state) => state.category.categories);
  const dispatch = useDispatch();

  useEffect(() => {
    getCategories(dispatch);
  }, [dispatch]);

  // const [cat, setCat] = useState([]);
  // const getCategorias = async () => {
  //   const res = await publicRequest.get("/categorias");
  //   setCat(res.data);
  // };
  // useEffect(() => {
  //     getCategorias();
  // }, []);

  return (
    <Container>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        breakpoints={{
          375: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          485: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
          1204: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1380: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        // className="mySwiper"
      >
        { categories.map((item, index) => {
          return (
            // <Wrapper>
            <SwiperSlide key={index} style={{height: "300px", margin: "0"}}>
              <Box>
                <Title>{item.name}</Title>
                <Links to={`/productos/${item.slug}`}>
                  <Button>Ver ahora</Button>
                </Links>
              </Box>
            </SwiperSlide>
            // </Wrapper>
          );
        })}
      </Swiper>
    </Container>
  );
};

export default CategoriesList;