import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "styles/responsive";

export const Wrapper = styled.div`
  margin-top: 70px;
  padding: 20px;
  ${mobile({ padding: "10px" })}
`;
export const Title = styled.h1`
  text-align: center;
`;
export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  ${mobile({ flexDirection: "column" })}
`;
export const TopButton = styled.button`
  padding: 8px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 10px;
`;
export const TopTexts = styled.div`
  ${mobile({ marginTop: "10px" })}
`;
export const TopText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 5px;
`;
export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })}
`;
export const Info = styled.div`
  flex: 3;
  padding: 5px;
  border-radius: 5px;
`;
export const Product = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  ${mobile({ flexDirection: "column" })}
`;
export const ProductDetail = styled.div`
  flex: 3;
  display: flex;
  ${mobile({ alignItems: "center" })}
`;
export const Image = styled.img`
  width: 150px;
  height: 120px;
  object-fit: contain;
  cursor: pointer;
  &:hover {
    filter: opacity(.5);
  }
  ${mobile({ width: "110px", height: "100px" })}
`;
export const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ${mobile({ fontSize: "14px" })}
`;
export const ProductName = styled.span``;
export const ProductId = styled.span``;
export const Box = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobile({ alignItems: "center", justifyContent: "space-between"})}
`;
export const PriceDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  ${mobile({ alignItems: "center", marginRight: "20px" })}
`;
export const ProductPrice = styled.div`
  font-size: 20px;
  font-weight: 200;
  ${mobile({ marginLeft: "15px" })}
`;
export const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 0.5px;
`;
export const Links = styled(Link)`
  text-decoration: none;
  color: #000;
`;
export const ButtonDelete = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
`;