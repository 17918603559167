import ReactPaginate from 'react-paginate';

export const Paginate = ({pageCount, changePage}) => {

  // Scroll al tocar los numeros de paginacion
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

    return (
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          pageCount={pageCount} // Cantidad de productos por pagina
          onPageChange={changePage}
          breakLabel={"..."} // Puntos supensivos que separan
          marginPagesDisplayed={2} // Cuantos numeros van antes y despues de la separacion
          pageRangeDisplayed={3} // Cantidad de los numeros del medio
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          onClick={scrollToTop}
        />
    )
}