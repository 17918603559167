
/* eslint-disable import/no-anonymous-default-export */
export default {
    body: {
        color: "#f8f9fa",
        color1: "#f3f4f7",
    },
    colors: {
        white: "#ffffff",

        black: "#000000",
        black1: "#282828",

        blue1: "#3483fa",
        blue2: "#3085d6",
        blue3: "#0e8ce4",

        gray: "#eeeeee",
        gray1: "#e6e6e6",
        gray2: "#888888",
        gray3: "#808080",
        gray4: "#555555",

        primary: "#10C98F",
        secondary: "#2ecc71",
        
        p: "#585858",
        subtitle: "#555555",
        label: "#808080",
        border: "#e6e6e6",
        accent: "#3b82f6",
        outline: "#3b82f6",

        // Colores de botones - ADMIN
        btnPrimary: "#00008b",
        btnSecondary: "#008080",
        btnAlternative: "#3bb077",
        btnCancel: "#dc2626",  
    },
    border: {
        r4: '4px',
        r8: '8px',
        r10: '10px',
        color: "#e6e6e6"
    },
    footer: {
        bgColor: "#e4f1ed",
        textColor: "#000000"
    }
}