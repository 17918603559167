import React, { useState } from 'react';
import LogoTienda from 'assets/images/logo-tienda.png';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Menu as MenuP, MenuItem, Button } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import { mobile } from 'styles/responsive';
import { logout } from 'features/user/userSlice';
import { limpiarCart } from 'features/cart/cartSlice';
import { limpiarFav } from 'features/fav/favSlice';
import './menu.css';
import {
  Menu,
  Close,
  FavoriteBorder,
  Dashboard,
  ExitToApp,
  Inbox,
  Person,
  AccountCircle,
  LocalMallOutlined,
} from '@material-ui/icons';

// const MenuLink = styled.a`
//   padding: 15px 20px;
//   cursor: pointer;
//   text-align: center;
//   text-decoration: none;
//   color: #000;
//   font-size: 0.9rem;
//   font-weight: bolder;
//   &:hover {
//     color: #e31c5c;
//   }
// `;
const MenuIcon = styled(Link)`
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #eee;
  transition: all 0.3s ease-in;
  font-size: 0.9rem;
  @media (max-width: 992px) {
    display: none;
    span {
      display: none;
    }
  }
`;
const Botton = styled.button`
  padding: 10px 15px;
  border: none;
  border-radius: ${(props) => props.theme.border.r10};
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  background-color: ${(props) => props.theme.colors.primary};
  color: #fff;
  transition: all 0.3s ease-in;
  font-size: 0.9rem;
  font-weight: bolder;
  letter-spacing: 0.6px;

  /* &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  } */
`;
const Nav = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  /* height: 70px; */
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  /* border-bottom: 1px solid #000; */
  /* box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.75); */
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`;
const Logo = styled(Link)`
  padding: 0.6rem;
  text-decoration: none;
  font-weight: 800;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  span {
    font-weight: 300;
    font-size: 1.3rem;
  }
  @media (max-width: 992px) {
    order: 2;
    padding: 0.6rem;
  }
`;
const MenuItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media (max-width: 992px) {
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    max-height: ${({ isOpen }) => (isOpen ? '700px' : '0')};
    transition: max-height 0.3s ease-in;
    width: 100%;
    order: 4;
  }
`;
const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  font-size: 30px;
  cursor: pointer;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @media (max-width: 992px) {
    display: flex;
    order: 1;
  }
`;
const Icons = styled.div`
  display: none;
  font-size: 30px;
  @media (max-width: 992px) {
    display: flex;
    order: 3;
  }
`;
const NavLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
  color: #000;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
  @media (max-width: 992px) {
    color: ${(props) => props.theme.colors.gray2};
    padding: 10px;
  }
`;
const NavLink2 = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bolder;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
  @media (max-width: 992px) {
    span {
      display: none;
    }
  }
`;
const A = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bolder;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
  @media (max-width: 992px) {
    span {
      display: none;
    }
  }
`;
const Boton = styled.span`
  padding: 9px 16px;
  font-weight: 600;
  border-radius: 10px;
  background-color: #${(props) => props.color};
  color: #${(props) => props.cl};
  display: flex;
  justify-content: center;
  align-items: center;
  /* ${mobile({ padding: '10px' })} */
`;
const LogoUser = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    display: none;
  }
`;
const Wrapper = styled.span`
  display: none;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    color: #000;
  }
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
  /* position: absolute; */
  object-fit: contain;
`;
const Hr = styled.hr`
  width: 100%;
  margin: 10px 0;
  background-color: #e6e6e6;
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
`;

const Header = () => {
  // state.cart => "cart" es el que defini en el store y ya tengo disponible quantity, product, total
  // Muestra la cantidad de productos en el carrito
  const quantity = useSelector((state) => state.cart.quantity);

  const user = useSelector((state) => state.user.currentUser);
  const admin = useSelector((state) => state.user.currentUser?.isAdmin);
  const fav = useSelector((state) => state.fav.quantity);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const products = useSelector((state) => state.product.products);
  const resProducts = products?.products?.filter((p) => p.discount > 0) || [];
  const categories = useSelector((state) => state.category.categories);

  const handleClick = () => {
    dispatch(logout());
    dispatch(limpiarCart());
    dispatch(limpiarFav());
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickE = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Funciones para recargar la pagina al hacer click y pueda obtener los datos
  const handleUpdate = () => {
    window.location.replace('/administrador');
  };
  const handleUpdateP = () => {
    window.location.replace('/pedidos');
  };

  return (
    <Nav>
      <Logo to='/'>
        <div style={{ width: '130px', height: '50px', color: '#000' }}>
          <Img src={LogoTienda} alt='Logo tienda' />
          {/* Mitienda. */}
        </div>
      </Logo>
      <Icons>
        <Link to='/favoritos'>
          <Badge badgeContent={fav} color='secondary'>
            <FavoriteBorder style={{ color: '#000', cursor: 'pointer' }} />
          </Badge>
        </Link>
        <Link to='/carrito'>
          <Badge badgeContent={quantity} color='secondary'>
            <LocalMallOutlined
              style={{ color: '#000', marginLeft: '15px', cursor: 'pointer' }}
            />
          </Badge>
        </Link>
      </Icons>
      <Hamburger onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <Close style={{ color: '#000', fontSize: '30px' }} />
        ) : (
          <Menu style={{ color: '#000', fontSize: '30px' }} />
        )}
      </Hamburger>
      <MenuItems isOpen={isOpen}>
        <NavLink to='/'>Home</NavLink>
        <div className='dropdown'>
          <button className='dropbtn'>Categorias</button>
          <div className='dropdown-content'>
            {categories.map((item, index) => (
              <a href={`/productos/${item.slug}`} key={index}>
                {item.name}
              </a>
            ))}
          </div>
        </div>
        <div className='dropdown2'>
          <details className='dropbtn2'>
            <summary>Categorias</summary>
            {categories.map((item, index) => (
              <button key={index} className='dropdown2-content'>
                <a href={`/productos/${item.slug}`} key={index}>
                  {item.name}
                </a>
              </button>
            ))}
          </details>
        </div>
        <NavLink to='/lista-productos'>Productos</NavLink>
        {resProducts.length > 0 && <NavLink to='/ofertas'>Ofertas</NavLink>}
        <NavLink to='/nosotros'>Nosotros</NavLink>
        <NavLink to='/contacto'>Contacto</NavLink>
        <Hr />
        {user && !admin && (
          <Wrapper>
            <NavLink to='/perfil'>
              <Person style={{ marginRight: '10px' }} /> Mi cuenta
            </NavLink>
            <NavLink to={'/pedidos'} onClick={handleUpdateP}>
              <Inbox style={{ marginRight: '10px' }} /> Mis pedidos
            </NavLink>
            <NavLink to='/' onClick={handleClick}>
              <ExitToApp style={{ marginRight: '10px' }} /> Cerrar sesión
            </NavLink>
          </Wrapper>
        )}
        {admin && (
          <Wrapper>
            {/* <NavLink to="/perfil">
              <Person style={{ marginRight: "10px" }} /> Mi cuenta
            </NavLink> */}
            <NavLink to='/administrador' onClick={handleUpdate}>
              <Dashboard style={{ marginRight: '10px' }} /> Dashboard
            </NavLink>
            <NavLink to='/' onClick={handleClick}>
              <ExitToApp style={{ marginRight: '10px' }} /> Cerrar sesión
            </NavLink>
          </Wrapper>
        )}
      </MenuItems>
      <MenuItems isOpen={isOpen}>
        {user ? (
          // <NavLink href="">Profile</NavLink>
          <>
            <Button
              onClick={handleClickE}
              // aria-controls="simple-menu"
              aria-haspopup='true'
              style={{
                color: '#000',
                margin: '3px',
                padding: '0',
                textTransform: 'lowercase',
              }}
            >
              <LogoUser>
                <AccountCircle
                  style={{ fontSize: '30px', marginRight: '5px' }}
                />{' '}
                {user.name}
              </LogoUser>
            </Button>
            <MenuP
              id='simple-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ top: '45px', left: '-50px' }}
            >
              {user && !admin && (
                <div>
                  <MenuItem onClick={handleClose}>
                    <NavLink2 to='/perfil'>
                      <Boton cl='000'>
                        <Person style={{ marginRight: '10px' }} /> Mi cuenta
                      </Boton>
                    </NavLink2>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <NavLink2 to={'/pedidos'} onClick={handleUpdateP}>
                      <Boton cl='000'>
                        <Inbox style={{ marginRight: '10px' }} /> Mis pedidos
                      </Boton>
                    </NavLink2>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <NavLink2 to='/'>
                      <Boton cl='000' onClick={handleClick}>
                        <ExitToApp style={{ marginRight: '10px' }} /> Cerrar
                        sesión
                      </Boton>
                    </NavLink2>
                  </MenuItem>
                </div>
              )}
              {admin && (
                <div>
                  {/* <MenuItem onClick={handleClose}>
                    <NavLink2 to="/perfil">
                      <Boton cl="000">
                        <Person style={{ marginRight: "10px" }} /> Mi cuenta
                      </Boton>
                    </NavLink2>
                  </MenuItem> */}
                  <MenuItem onClick={handleClose}>
                    <A href='/administrador'>
                      <Boton cl='000' style={{ padding: '5px 10px' }}>
                        <Dashboard style={{ marginRight: '10px' }} /> Dashboard
                      </Boton>
                    </A>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <NavLink2 to='/'>
                      <Boton
                        cl='000'
                        onClick={handleClick}
                        style={{ padding: '5px 10px' }}
                      >
                        <ExitToApp style={{ marginRight: '10px' }} /> Cerrar
                        sesión
                      </Boton>
                    </NavLink2>
                  </MenuItem>
                </div>
              )}
            </MenuP>
          </>
        ) : (
          <NavLink to='/iniciar-sesion'>
            <Botton color='000'>Iniciar sesión</Botton>
          </NavLink>
        )}

        <MenuIcon to=''>
          <span style={{ color: '#000' }}> | </span>
        </MenuIcon>
        <MenuIcon to='/favoritos'>
          <span>
            <Badge badgeContent={fav} color='secondary'>
              <FavoriteBorder style={{ color: '#000', cursor: 'pointer' }} />
            </Badge>
          </span>
        </MenuIcon>
        <MenuIcon to='/carrito'>
          <span>
            <Badge badgeContent={quantity} color='secondary'>
              <LocalMallOutlined style={{ color: '#000', cursor: 'pointer' }} />
            </Badge>
          </span>
        </MenuIcon>
      </MenuItems>
    </Nav>
  );
};

export default Header;
