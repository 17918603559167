import styled from 'styled-components';
import { mobile } from 'styles/responsive';

const Wrapper = styled.div`
  margin-top: 70px;
  padding: 50px;
  display: flex;
  ${ mobile({padding: "10px", flexDirection: "column"}) }
`;
const ImgContainer = styled.div`
  flex: 1;
`;
const ImageBig = styled.img`
  width: 100%;
  height: 50vh;
  ${ mobile({height: "35vh"}) }

  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  -webkit-animation: 1.5s bg-loading linear infinite;
  animation: 1.5s bg-loading linear infinite;

  @keyframes bg-loading {
    to {
        background-position-x: -200%;
    }
  }
`;
const ImageSmall = styled.img`
  width: 100%;
  height: 20vh;
  ${ mobile({height: "10vh"}) }

  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  -webkit-animation: 1.5s bg-loading linear infinite;
  animation: 1.5s bg-loading linear infinite;

  @keyframes bg-loading {
    to {
        background-position-x: -200%;
    }
  }
`;
const InfoContainer = styled.div`
  flex: 1;
  padding: 0px 50px;
  ${ mobile({padding: "10px 0"}) }
`;
const Title = styled.h1`
  font-weight: 600;
  height: 70px;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  -webkit-animation: 1.5s bg-loading linear infinite;
  animation: 1.5s bg-loading linear infinite;

  @keyframes bg-loading {
    to {
        background-position-x: -200%;
    }
  }
`;
const Desc = styled.p`
  margin: 10px 0px;
  color: #475569;
  height: 40px;

  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  -webkit-animation: 1.5s bg-loading linear infinite;
  animation: 1.5s bg-loading linear infinite;

  @keyframes bg-loading {
    to {
        background-position-x: -200%;
    }
  }
`;
const P = styled.p`
  margin: 10px 0 0;
  color: #475569;
  height: 40px;

  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  -webkit-animation: 1.5s bg-loading linear infinite;
  animation: 1.5s bg-loading linear infinite;

  @keyframes bg-loading {
    to {
        background-position-x: -200%;
    }
  }
`;
// const Price = styled.span`
//   font-weight: bold;
//   font-size: 30px;
//   color: #e31c5c;
// `;
const FilterContainer = styled.div`
  width: 80%;
  height: 60px;
  /* margin: 10px 0; */
  display: flex;
  justify-content: space-between;
  ${ mobile({width: "100%"}) }

  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  -webkit-animation: 1.5s bg-loading linear infinite;
  animation: 1.5s bg-loading linear infinite;

  @keyframes bg-loading {
    to {
        background-position-x: -200%;
    }
  }
`;
const Filter = styled.div`
  display: flex;
  align-items: center;
`;
const FilterTitle = styled.span`
  font-size: 18px;
  font-weight: 400;
`;
const FilterSize = styled.p`
  margin-left: 10px;
  padding: 5px;
  border-radius: 10px;
`;
const FilterSizeOption = styled.option`
`;
const AddContainer = styled.div`
  width: 80%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  ${ mobile({width: "100%", marginTop: '20px'}) }

  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  -webkit-animation: 1.5s bg-loading linear infinite;
  animation: 1.5s bg-loading linear infinite;

  @keyframes bg-loading {
    to {
        background-position-x: -200%;
    }
  }
`;
const AddContainer2 = styled.div`
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  ${ mobile({width: "100%", marginTop: '20px'}) }

  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  -webkit-animation: 1.5s bg-loading linear infinite;
  animation: 1.5s bg-loading linear infinite;

  @keyframes bg-loading {
    to {
        background-position-x: -200%;
    }
  }
`;
const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  padding: 5px;
  border-radius: 30px;
`;
const Amount = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
`;
const Button = styled.button`
  padding: 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #fff;
`;
const ButtonFav = styled.button`
  margin-left: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
`;
const BoxImages = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
`;

const ProductSkeleton = () => {

  return (

      <Wrapper>
        <ImgContainer>
          <ImageBig  alt='' loading="lazy"/>
          <BoxImages>
            <div>
              <ImageSmall loading="lazy"/>
            </div>
            <div>
              <ImageSmall loading="lazy"/>
            </div>
            <div>
              <ImageSmall loading="lazy"/>
            </div>
          </BoxImages>
        </ImgContainer>

        <InfoContainer>
          <Title></Title>
          <Desc></Desc>
          <P></P>
          {/* <Price></Price>  */}
          <ButtonFav></ButtonFav>
          
          <FilterContainer>
            <Filter>
              <FilterTitle></FilterTitle>
              <FilterSize>
                <FilterSizeOption></FilterSizeOption>
              </FilterSize>
            </Filter>

            <Filter style={{ marginRight: "10px"}}>
              <FilterTitle></FilterTitle>
              <FilterSize>
                <FilterSizeOption></FilterSizeOption>
              </FilterSize>
            </Filter>
          </FilterContainer>

          <AddContainer>
            <AmountContainer>
              <Amount></Amount>
            </AmountContainer>
            <Button><p style={{ marginLeft: "5px"}}></p></Button>
          </AddContainer>

          <AddContainer2>

          </AddContainer2>

        </InfoContainer>
      </Wrapper>
  );
};

export default ProductSkeleton;