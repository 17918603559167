import React from "react";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";

import styled from "styled-components";
import { mobile } from "styles/responsive";

const Container = styled.div``;
const Wrapper = styled.div`
  margin-top: 80px;
  padding: 0 50px;
  margin-bottom: 100px;
  ${mobile({ padding: "0 20px" })};
`;
const Static = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
`;
const Title = styled.h1`
  font-weight: 200;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 50px;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 25px;
  }
`;
const Subtitle = styled.h4`
  margin: 20px 0px;
  color: #000;
`;
const Desc = styled.p`
  margin: 20px 0px;
  color: #000;
`;

const Envio = () => {
  return (
    <Container>
      <Static>
        <Header />
      </Static>
      <Wrapper>
        <Title>Envío</Title>
        <Subtitle>
          ¿CUÁNTO TARDARÁ EN LLEGAR MI PEDIDO?
        </Subtitle>
        <Desc>
          Para los envíos, el plazo de entrega es de un máximo de 3 días
          laborables (24-72h) desde que sale de tienda.
          <br />
          Es necesario notificar los posibles errores o desperfectos en los
          envíos en las siguientes 24h desde la recepción del pedido,
          comunicándolo por Email a {""}
          <ins style={{ color: "blue" }}>(aqui va el correo)</ins> o llamando
          a tienda <strong>(aqui va el teléfono)</strong>.
        </Desc>
        <Subtitle>¿CUÁNTO CUESTA EL ENVÍO?</Subtitle>
        <Desc>Los envíos cuestan $600.</Desc>
      </Wrapper>
      <Footer />
    </Container>
  );
};

export default Envio;
