import { useState, useEffect } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { publicRequest, userRequest } from 'config/clienteAxios';
import { alertSuccess } from 'components/alerts/SweetAlert';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  BoxButtons,
  BoxInput,
  Form,
  Input,
  Label,
  Title,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

const CategoryEdit = () => {
  const location = useLocation();
  const catId = location.pathname.split('/')[2];
  const history = useHistory();
  const [data, setData] = useState({
    name: '',
    talla: [],
  });
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  const handleInputChange = (e) => {
    setData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  // const handleSize = (e) => {
  //   let arr = [];
  //   arr = e.target.value.split(",");
  //   const res = arr.map((c) => c.trim());
  //   setTalla(res);
  // };

  const getCategoryId = async () => {
    try {
      const res = await publicRequest.get(`categorias/find/${catId}`, {
        headers: { token: accessToken },
      });
      setData(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getCategoryId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { name, talla } = data;

    // Convierto la cadena en un array
    // let arr = [];
    // arr = talla.split(",");
    // const res = arr.map((c) => c.trim())

    const categoria = { name, talla, id: data?.id };

    try {
      const updateCategory = async () => {
        await userRequest.put(`/categorias/${catId}`, categoria, {
          headers: { token: accessToken },
        });
        alertSuccess('¡Categoria actualizada!');
        history.push('/categorias');
      };
      updateCategory();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <Title>Editar Categoria</Title>
          <Form>
            <BoxInput>
              <Label>Nombre Categoria</Label>
              <Input
                type='text'
                name='name'
                value={data.name}
                onChange={handleInputChange}
              />
            </BoxInput>
            {/* <BoxInput>
              <Label style={{ marginTop: "10px" }}>Tallas</Label>
              <Input
                type="text"
                name="talla"
                value={data.talla}
                onChange={handleInputChange}
              />
            </BoxInput> */}
            <BoxButtons>
              <Button
                color='#fff'
                padding='10px 15px'
                margin='10px 10px 0 0'
                onClick={handleFormSubmit}
              >
                Actualizar
              </Button>
              <Link to='/categorias' className='link'>
                <Button
                  bgColor='cancel'
                  color='#fff'
                  padding='10px 15px'
                  margin='10px 10px 0 0'
                >
                  Cancelar
                </Button>
              </Link>
            </BoxButtons>
          </Form>
        </Wrapper>
      </div>
    </>
  );
};

export default CategoryEdit;
