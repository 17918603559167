import React from "react";

import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { NavigationAdmin } from "utils/navigation";
import { mobile } from "styles/responsive";

const Container = styled.div`
  flex: 1;
  height: calc(100vh - 50px);
  background-color: #eff6ff;
  position: sticky;
  top: 0;
  padding: 20px;
  color: #555;

  h3 {
    font-size: 16px;
    color: #888;
  }

  ul {
    list-style: none;
    padding: 5px 0;

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 10px;

      &:hover {
        background-color: #bfdbfe;
      }

      span {
        display: flex;
        align-items: center;
        margin-right: 5px;
      }
    }
  }
  ${mobile({ display: "none" })}
`;


const Sidebar = () => {
  return (
    <Container>
      <h3>Dashboard</h3>
      <ul>
        {NavigationAdmin.map((item, index) => (
          <li key={index}>
            <NavLink to={item.path} className="link" activeClassName="active" key={index} style={{ display: "flex", width: "100%", padding: "5px 10px"}}>
              <span>{item.icon}</span>
              <span>{item.name}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default Sidebar;
