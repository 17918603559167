import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { userRequest } from 'config/clienteAxios';
import { currencyFormat } from 'utils/data-conversion';
import { mobile } from 'styles/responsive';

const Container = styled.div`
  flex: 2;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  padding: 20px;

  h3 {
    font-size: 22px;
    font-weight: 600;
    ${mobile({ fontSize: '20px' })}
  }
  table {
    width: 100%;
    border-spacing: 10px;
    tbody {
      tr {
        th {
          text-align: left;
          font-size: 16px;
          font-weight: 600;

          /* Ocultara los primeros dos elementos */
          &:nth-child(-n + 2) {
            @media (max-width: 485px) {
              display: none;
            }
          }
        }
      }
    }
  }
  ${mobile({ width: '295px', marginBottom: '10px' })}
`;
const TDStatus = styled.td`
  padding: 5px 7px;
  border: none;
  border-radius: 10px;
`;

const WidgetLg = () => {
  const [orders, setOrders] = useState([]);
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  useEffect(() => {
    const getOrders = async () => {
      try {
        const res = await userRequest.get('/ordenes/?size=5', {
          headers: { token: accessToken },
        });
        setOrders(res.data.orders);
      } catch (error) {}
    };
    getOrders();
  }, [accessToken]);

  return (
    <Container>
      <h3>Últimas transacciones</h3>
      <table>
        <tbody>
          <tr>
            <th>Cliente</th>
            <th>Fecha</th>
            <th>Total</th>
            <th>Estado</th>
            <th>Detalle</th>
          </tr>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>
                <b>
                  {order.userId ? order.userId?.name : order.userInvite?.name}{' '}
                  {order.userId
                    ? order.userId?.lastName
                    : order.userInvite?.lastName}
                </b>
              </td>
              <td>{order.fecha}</td>
              <td>{currencyFormat(order.total)}</td>
              <td>
                <button
                  style={{
                    backgroundColor: '#cfe2ff',
                    border: 'none',
                    borderRadius: '20px',
                  }}
                >
                  <span style={{ margin: '0 5px' }}>{order.status}</span>
                </button>
              </td>
              <TDStatus>
                <Link
                  to={'/viewOrden/' + order._id}
                  style={{
                    color: 'blue',
                    fontSize: '14px',
                    textDecoration: 'underline',
                  }}
                >
                  Ver
                </Link>
              </TDStatus>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default WidgetLg;
