import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Check, Warning } from '@material-ui/icons';
import { publicRequest } from 'config/clienteAxios';
import { mobile } from 'styles/responsive';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
`;
const AlertSuccess = styled.p`
    padding: 2rem;
    color: white;
    font-size: 25px;
    border-radius: 80px;
    background-color: green;
    ${mobile({ textAlign: "center", margin: "0 5px", fontSize: "20px"})};
`;
const AlertError = styled.p`
    padding: 2rem;
    color: white;
    font-size: 25px;
    border-radius: 80px;
    background-color: rgba(255, 0, 0, 0.7);
    ${mobile({ textAlign: "center", margin: "0 5px", fontSize: "20px"})};
`;

const ConfirmEmail = () => {

    const location = useLocation();
    const token = location.pathname.split('/')[2];
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(true);
    const [msg, setMsg] = useState('');
    // console.log(token);

    useEffect(() => {
        const confirmAccount = async () => {
            try {
                const res = await publicRequest.get(`/auth/confirmar/${token}`);
                // console.log(res.data.success);
                setSuccess(res.data.success);
                setError(res.data.success);
                setMsg(res.data.msg);
            } catch (error) {
                
            }
        }
        confirmAccount();
    }, [token]);

  return (
    <Container>
        { success && <AlertSuccess> <Check style={{ fontSize: "25px", marginRight: "10px"}} /> {msg} </AlertSuccess> }
        { !error && <AlertError> <Warning style={{ fontSize: "25px", marginRight: "10px"}} /> {msg} </AlertError> }    
    </Container>
    );
};

export default ConfirmEmail;
