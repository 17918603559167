import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile, mobileLg, mobileMd } from "styles/responsive";

export const Wrapper = styled.div`
  margin-top: 70px;
  padding: 20px;
  ${mobile({ padding: "10px" })}
`;

export const Title = styled.h1`
  text-align: center;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  ${mobile({ flexDirection: "column" })}
`;

export const TopButton = styled.button`
  padding: 8px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 10px;
`;

export const TopTexts = styled.div`
  ${mobile({ marginTop: "10px" })}
`;
export const TopText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 5px;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobileLg({ flexDirection: "column" })}
`;

export const Info = styled.div`
  flex: 3;
`;

export const Product = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px;
  border-bottom: 0.5px solid #eee;
  ${mobileMd({ flexDirection: "column" })}
`;

export const ProductDetail = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  ${mobile({ alignItems: "center" })}
`;

export const Image = styled.img`
  width: 150px;
  height: 120px;
  object-fit: contain;
  cursor: pointer;
  &:hover {
    filter: opacity(0.5);
  }
  ${mobile({ width: "110px", height: "100px" })}
`;

export const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ${mobileMd({ width: "100%", fontSize: "14px", paddingRight: "0" })}
  ${mobile({  })}
`;

export const ProductName = styled.span``;

export const ProductId = styled.span`
  font-size: 14px;
`;

export const ProductColor = styled.div`
  font-size: 14px;
`;

export const ProductSize = styled.span`
  font-size: 14px;
`;

export const Box = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  ${mobile({ alignItems: "center", justifyContent: "space-evenly" })}
`;

export const PriceDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  ${mobile({ alignItems: "center", marginRight: "20px" })}
`;

export const ProductAmountContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ProductAmount = styled.div`
  font-size: 20px;
  margin: 5px;
  ${mobile({ margin: "5px 15px" })}
`;

export const ProductPrice = styled.div`
  font-size: 25px;
  font-weight: 600;
  ${mobileMd({ fontSize: "20px" })}
`;

export const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 0.5px;
`;

export const Summary = styled.div`
  flex: 1;
  background-color: #fff;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  height: 80vh;
  margin-bottom: 20px;
  ${mobileLg({ width: "80%", margin: "20px auto" })}
  ${mobile({ marginBottom: "0" })}
`;

export const SummaryTitle = styled.h1`
  font-size: 25px;
  font-weight: 600;
  text-align: center;
`;

export const SummaryItem = styled.div`
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => props.type === "total" && "600"};
  font-size: ${(props) => props.type === "total" && "24px"};
`;

export const SummaryItemText = styled.span``;

export const SummaryItemPrice = styled.span``;

export const Button = styled.button`
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 10px;
  background-color: #040404;
  color: white;
  font-weight: 600;
  cursor: pointer;
`;

export const Links = styled(Link)`
  text-decoration: none;
  color: #000;
`;

export const ButtonDelete = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
`;

export const CodigoP = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  border: 1px solid #000;
  z-index: 10;
`;

export const Input = styled.input`
  width: 85%;
  padding: 10px;
  border: none;
  outline: none;
`;

export const Btn = styled.button`
  width: 55%;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  background-color: #a0a0a0;
  cursor: pointer;
  &:hover {
    background-color: #c7c5c5;
  }
  ${mobile({ fontSize: "13px" })}
`;