import { createSlice } from '@reduxjs/toolkit';

const favSlice = createSlice({
    name: "fav",
    initialState: {
        products: [],
        quantity: 0,
        // total: 0,
    },
    reducers: {
        // ------------------ AGREGAR PRODUCTO A FAVORITOS ------------------
        addProductFav: (state, action) => {
            state.quantity += 1;
            state.products.push(action.payload);
            // state.total += action.payload.price * action.payload.quantity;
        },
        // ------------------ ELIMINAR PRODUCTO DE FAVORITOS ------------------
        deleteProductFav: (state, action) => {
            state.quantity -= 1;
            state.products.splice(state.products.findIndex((item) => item._id === action.payload.id),1);
            // state.total -= action.payload.price * action.payload.quantity;
        },
        // ------------------ LIMPIAR FAVORITOS ------------------
        limpiarFav: (state, action) => {
            state.quantity = 0;
            state.products = []
        },
    },
});

export const { addProductFav, deleteProductFav, limpiarFav } = favSlice.actions;
export default favSlice.reducer;