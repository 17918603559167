import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { userRequest } from 'config/clienteAxios';
import { Link, useHistory } from 'react-router-dom';
import Layout from 'components/layouts/Layout';
import {
  BoxInput,
  Button,
  Container,
  Form,
  Input,
  Title,
  Wrapper,
} from './ProfileEditStyles';
import { editUser } from 'features/user/userThunks';

const ProfileEdit = () => {
  const user = useSelector((state) => state.user.currentUser);
  const { id } = user;
  const dispatch = useDispatch();

  const [usuario, setUsuario] = useState({
    name: '',
    lastName: '',
    dni: '',
    email: '',
    address: '',
    tel: '',
    poblac: '',
    prov: '',
    codPostal: '',
  });

  const history = useHistory();
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  const getUserId = async () => {
    const res = await userRequest.get(`/usuarios/find/${id}`, {
      headers: { token: accessToken },
    });
    setUsuario(res.data.data);
  };

  const handleInputChange = (e) => {
    setUsuario((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const updateUser = async () => {
    await dispatch(editUser(usuario));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    updateUser();
    history.push('/perfil');
  };

  useEffect(() => {
    getUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(usuario);
  }, [usuario]);

  return (
    <Layout title='Perfil usuario'>
      <Container>
        <Wrapper>
          <Title>
            <span>EDITAR PERFIL</span>
          </Title>

          <Form onSubmit={handleFormSubmit}>
            <BoxInput>
              <Input
                type='text'
                name='name'
                value={usuario?.name || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Input
                type='text'
                name='lastName'
                placeholder='Apellidos'
                value={usuario?.lastName || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Input
                type='text'
                name='dni'
                placeholder='Ingrese su DNI'
                value={usuario?.dni || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Input
                type='email'
                name='email'
                placeholder='Correo electrónico'
                value={usuario?.email || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Input
                type='text'
                name='address'
                placeholder='Dirección'
                value={usuario?.address || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Input
                type='tel'
                name='tel'
                placeholder='Teléfono (cod. área + número)'
                value={usuario?.tel || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Input
                type='text'
                name='poblac'
                placeholder='Población'
                value={usuario?.poblac || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Input
                type='text'
                name='prov'
                placeholder='Provincia'
                value={usuario?.prov || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Input
                type='text'
                name='postalCod'
                placeholder='Código Postal'
                value={usuario?.postalCod || ''}
                onChange={handleInputChange}
              />
            </BoxInput>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button type='submit'>Actualizar</Button>
              <Button type='submit' style={{ backgroundColor: '#dc2626' }}>
                <Link className='link' to='/perfil'>
                  Cancelar
                </Link>
              </Button>
            </div>
          </Form>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default ProfileEdit;
