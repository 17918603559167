import React, { useEffect } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { alertDelete } from 'components/alerts/SweetAlert';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  Image,
  Span,
  Table,
  TableContainer,
  Title,
  TitleContainer,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { Link } from 'react-router-dom';
import { DeleteOutline, Edit } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from 'api/apiCalls';
import { DataGridTable } from 'utils/data-grid-table';
import { currencyFormat } from 'utils/data-conversion';
import { deleteProduct } from 'features/product/productThunks';

const ProductList = () => {
  const dispatch = useDispatch();
  const productsState = useSelector((state) => state.product.products);
  const products =
    productsState?.products?.filter((item) => item.status !== false) || [];
  console.log(productsState);

  const delProduct = async (id) => {
    await dispatch(deleteProduct(id));
  };

  const handleDelete = (id) => {
    alertDelete(delProduct, id, 'Producto eliminado');
    getProducts(dispatch);
  };

  useEffect(() => {
    getProducts(dispatch);
  }, [dispatch]);

  const columns = [
    // { field: "_id", headerName: "ID", width: 100 },
    {
      field: 'cod',
      headerName: 'Código',
      width: 120,
    },
    {
      field: 'product',
      headerName: 'Producto',
      width: 170,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Image src={params.row.img?.[0]} alt='' />
            {params.row.title}
          </div>
        );
      },
    },

    {
      field: 'price',
      headerName: 'Precio',
      width: 120,
      renderCell: (params) => {
        return <div>{currencyFormat(params.row.price)}</div>;
      },
    },
    {
      field: 'categories',
      headerName: 'Categorias',
      width: 115,
      renderCell: (params) => {
        return <div>{params.row.categories}</div>;
      },
    },
    {
      field: 'details.measures',
      headerName: 'Medidas',
      width: 115,
      renderCell: (params) => {
        return (
          <div>
            {params.row.details.map((i, index) => (
              <span key={index} style={{ fontSize: '12px' }}>
                /{i.measures}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      field: 'details.color',
      headerName: 'Color',
      width: 120,
      renderCell: (params) => {
        return (
          <div>
            {params.row.details.map((i, index) => (
              <span key={index} style={{ fontSize: '12px' }}>
                /{i.color}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      field: 'details.stock',
      headerName: 'Stock',
      width: 116,
      renderCell: (params) => {
        return (
          <div>
            {params.row.details
              .map((item) => Number(item.stock))
              .reduce((prev, curr) => prev + curr, 0)}
          </div>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Acciones',
      width: 145,
      renderCell: (params) => {
        return (
          <>
            <Link to={'/editProducto/' + params.row.id}>
              <Button
                bgColor='alternative'
                margin='0 10px 0 0'
                padding='5px 7px'
                color='#fff'
              >
                <Edit />
              </Button>
            </Link>

            <Button
              bgColor='cancel'
              padding='5px 7px'
              color='#fff'
              onClick={() => handleDelete(params.row.id)}
            >
              <DeleteOutline />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <TitleContainer>
            <Title>
              Productos <Span>({products.length} productos)</Span>
            </Title>
            <Link to='/nuevoProducto'>
              <Button
                w='80px'
                bgColor='secondary'
                color='#fff'
                fw={100}
                fs='16px'
                padding='5px'
              >
                Crear
              </Button>
            </Link>
          </TitleContainer>

          <TableContainer>
            <Table>
              <DataGridTable data={products} columns={columns} />
            </Table>
          </TableContainer>
        </Wrapper>
      </div>
    </>
  );
};

export default ProductList;
