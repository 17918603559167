import styled from "styled-components";
import { mobile } from "styles/responsive";

export const Container = styled.div`
  min-height: 90vh;
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;
export const Wrapper = styled.div`
  width: 800px;
  border-radius: 8px;
  -webkit-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.53);
  box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.53);
  ${mobile({ height: "100vh", margin: "0 20px 20px" })}
`;
export const Title = styled.h3`
  margin: 20px;
  padding: 0 10px;
  font-size: 20px;
  background-color: #eee;
  font-weight: 600;
  display: flex;
  justify-content: center;
`;
export const Form = styled.form`
  width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  ${mobile({ width: "300px" })}
`;
export const Input = styled.input`
  flex: 1;
  min-width: 40%;
  padding: 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0 5px;
`;
export const BoxInput = styled.div`
  display: flex;
  border: 2px solid #eee;
  border-radius: 10px;
  margin-top: 10px;
`;
export const Button = styled.button`
  width: 100%;
  border: none;
  padding: 15px;
  background-color: #000;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  margin: 20px 10px;
  font-weight: bolder;
`;