import React, { useState, useEffect } from "react";
import LogoTienda from "assets/images/logo-tienda.png";
import Spinner from "components/spinner/Spinner";

import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { userRequest } from "config/clienteAxios";
import { currencyFormat } from "utils/data-conversion";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { mobile } from "styles/responsive";
import OrderPDF from "../../administrador/order/OrderPDF";
import styles from "../../administrador/order/orderView.module.css";
import Layout from "components/layouts/Layout";

const Container = styled.div`
  min-height: 90vh;
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;
const Wrapper = styled.div`
  width: 800px;
  border-radius: 8px;
  flex: 1;
  margin: 0 50px;
  ${mobile({ margin: "0 10px 20px" })}
`;
const Tabla = styled.table`
  width: 80%;
  margin-left: 10px;
  margin-bottom: 10px;
  border: 1px solid #000;
  border-collapse: collapse;
  ${mobile({ display: "none" })}
`;

const ViewOrder = () => {
  const location = useLocation();
  const orderId = location.pathname.split("/")[2];
  const [order, setOrder] = useState({});

  useEffect(() => {
    const getOrderId = async () => {
      const res = await userRequest.get(`/ordenes/${orderId}`);
      setOrder(res.data[0]);
      // console.log(res.data[0]);
    };
    getOrderId();
  }, [orderId]);

  const {
    // _id,
    numPedido,
    fecha,
    hora,
    subtotal,
    envio,
    adicional,
    total,
    cuponDescuento,
    formaEnvio,
    formaPago,
    // status,
    userId,
    products,
  } = order;

  const handleMostarDesc = (total) => {
    if (cuponDescuento) {
      if (cuponDescuento.tipo === "porcentaje") {
        const des = (total * cuponDescuento.descuento) / 100;
        const res = des.toFixed(2);
        return res;
      }
      if (cuponDescuento.tipo === "importe") {
        const res = cuponDescuento.descuento;
        return res;
      }
    }
    return total;
  };

  return (
    <Layout title='Detalles del Pedido'>
      <Container>
        <Wrapper>
          <div className={styles.userTitleContainer}>
            <Link to="/pedidos">Atras</Link>
            <h1 className={styles.userTitle}>Orden</h1>
            <PDFDownloadLink
              document={<OrderPDF order={order} />}
              fileName="Detalle_Orden"
            >
              <button className={styles.imprimirPDF}>Descargar</button>
            </PDFDownloadLink>
          </div>
          <div className={styles.userContainer}>
            {Object.keys(order).length ? (
              <div className={styles.userShow}>
                <div className={styles.userShowTop}>
                  <img
                    src={LogoTienda}
                    alt="Logo Aire De Lola"
                    width={"40px"}
                    style={{ marginLeft: "20px", marginTop: "10px" }}
                  />
                </div>
                <div className={styles.userShowBottom}>
                  <div className={styles.main__orden}>
                    <div>
                      <div className={styles.userShowInfo}>
                        <span className={styles.userShowInfoTitle}>
                          {" "}
                          <strong>(Nombre del responsable)</strong>
                        </span>
                      </div>
                      <div className={styles.userShowInfo}>
                        <span className={styles.userShowInfoTitle}>
                          {" "}
                          <strong>(DNI)</strong>
                        </span>
                      </div>
                      <div className={styles.userShowInfo}>
                        <span className={styles.userShowInfoTitle}>
                          {" "}
                          <strong>
                            (Direccion)
                          </strong>
                        </span>
                      </div>
                      <div className={styles.userShowInfo}>
                        <span className={styles.userShowInfoTitle}>
                          {" "}
                          <strong>Teléfono:</strong> (Telefono)
                        </span>
                      </div>
                      <div
                        className={styles.userShowInfo}
                        style={{ marginTop: "30px" }}
                      >
                        <span className={styles.userShowInfoTitle}>
                          {" "}
                          <strong>N° Pedido:</strong> {numPedido}
                        </span>
                      </div>
                      <div className={styles.userShowInfo}>
                        <span className={styles.userShowInfoTitle}>
                          {" "}
                          <strong>Fecha:</strong> {fecha}
                          <strong>Hora:</strong> {hora}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className={styles.userShowInfoUser}>
                        <span className={styles.userShowInfoTitle}>
                          {" "}
                          <strong>Nombre y apellidos:</strong> {userId?.name}{" "}
                          {userId?.lastName}
                        </span>
                      </div>
                      <div className={styles.userShowInfoUser}>
                        <span className={styles.userShowInfoTitle}>
                          {" "}
                          <strong>Dirección:</strong> {userId?.address}
                        </span>
                      </div>
                      <div className={styles.userShowInfoUser}>
                        <span className={styles.userShowInfoTitle}>
                          {" "}
                          <strong>{userId?.codPostal}</strong>
                        </span>
                      </div>
                      <div className={styles.userShowInfoUser}>
                        <span className={styles.userShowInfoTitle}>
                          {" "}
                          <strong>
                            {userId?.city} - {userId?.prov}
                          </strong>
                        </span>
                      </div>
                      <div className={styles.userShowInfoUser}>
                        <span className={styles.userShowInfoTitle}>
                          {" "}
                          <strong>DNI:</strong> {userId?.dni}
                        </span>
                      </div>
                      <div className={styles.userShowInfoUser}>
                        <span className={styles.userShowInfoTitle}>
                          {" "}
                          <strong>Teléfono:</strong> {userId?.tel}
                        </span>
                      </div>
                      <div className={styles.userShowInfoUser}>
                        <span className={styles.userShowInfoTitle}>
                          {" "}
                          <strong>{userId?.email}</strong>
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* TABLA DE PRODUCTOS */}
                  <div
                    className={styles.userShowInfo}
                    style={{ marginTop: "30px" }}
                  >
                    <span className={styles.userShowInfoTitle}>
                      {" "}
                      <strong>Productos</strong>{" "}
                    </span>
                  </div>
                  <div className={styles.userShowInfoProduct}>
                    <Tabla>
                      <thead>
                        <tr>
                          <th className={styles.th}>Código</th>
                          <th className={styles.th}>Descripcion</th>
                          <th className={styles.th}>Medidas</th>
                          <th className={styles.th}>Color</th>
                          <th className={styles.th}>Cant.</th>
                          <th className={styles.th}>Precio</th>
                          <th className={styles.th}>Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products?.map((product, index) => (
                          <tr key={index}>
                            <td className={styles.td}>{product.cod}</td>
                            <td className={styles.td}>{product.name}</td>
                            <td className={styles.td}>{product.measures}</td>
                            <td className={styles.td}>{product.color}</td>
                            <td className={styles.td}>{product.quantity}</td>
                            <td className={styles.td}>
                              {currencyFormat(product.price)}
                            </td>
                            <td className={styles.td}>
                              {currencyFormat(product.price * product.quantity)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Tabla>
                  </div>
                  <div className={styles.userShowInfoUser}>
                    <div
                      className={`${styles.userShowInfoTitle} ${styles.flex}`}
                    >
                      {" "}
                      <div>
                        <strong>Forma de Pago:</strong>{" "}
                        {formaPago?.toUpperCase()}
                      </div>
                      {formaEnvio === "retiradaentienda" ? (
                        <div>
                          <strong>Retirada en tienda</strong>
                        </div>
                      ) : (
                        <div>
                          <strong>Envio:</strong> {currencyFormat(envio)}
                        </div>
                      )}
                      {formaPago === "contrareembolso" && (
                        <div>
                          <strong>Adic. Contrareembolso:</strong>{" "}
                          {currencyFormat(adicional)}
                        </div>
                      )}
                      {cuponDescuento === 0 ? null : (
                        <div>
                          <strong>Cupón descuento:</strong> -
                          {currencyFormat(handleMostarDesc(subtotal))}{" "}
                        </div>
                      )}
                      <div>
                        <strong>Total:</strong> {currencyFormat(total)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default ViewOrder;
