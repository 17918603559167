import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  *, *::after, *::before {
      padding: 0;
      margin: 0;
      font-family: "Poppins", sans-serif;
  }

  body {
    background-color: ${props => props.theme.body.color};
  }

  h1, h2, h3, h4, h5 {
    color: ${props => props.theme.colors.black};
  }
  p {
    color: ${props => props.theme.colors.p};
  }

  a {
    color: #f2f2f2;
    text-decoration: none;
  }

  .container {
    display: flex;
    margin-top: 62px;
  }

  // Estilos para botones desabilitados
  .disabled:disabled {
    background: #808080;
    cursor: default;
  }

  // Para estilos del LINK 
  .link {
      text-decoration: none;
      color: inherit;
  }

  .active {
    background-color: #bfdbfe;
    border-radius: 10px;
  }
  
  // Para estilos del scroll de administrador
  * {
    scrollbar-width: thin;
    scrollbar-color: white black;
  }
  *::-webkit-scrollbar {
    width: 12px;
  }
  
  *::-webkit-scrollbar-track {
    background: #eee;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.gray3};
    border-radius: 20px;
    border: 1px solid lightgray;
  }

  // Para la paginacion de los productos
  .paginationBttns {
    width: 100%;
    height: 50px;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  .paginationBttns a {
    padding: 6px 12px;
    border-radius: 20px;
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
    font-weight: 600;
  }
  .paginationActive a {
    color: white;
    background-color: ${(props) => props.theme.colors.primary};
  }
  .paginationDisabled a {
    color: grey;
    background-color: transparent;
    cursor: default;
  }

  // Sacar el efecto color azul al dar click a un enlace
  a, button, details { 
    -webkit-tap-highlight-color: rgba(0,0,0,0); 
  }
`;

export default GlobalStyle;
