import { useEffect, useState } from "react";
import Spinner from "components/spinner/Spinner";

import { Link } from "react-router-dom";
import { Box, Btn, Container, Div, P, Title } from "./ErrorStyles";

const Error = () => {
  const [cargando, setCargando] = useState(false);

  useEffect(() => {
    setCargando(true);

    setTimeout(() => {
      setCargando(false);
    }, 2000);
  }, []);

  return (
    <>
      {cargando ? (
        <div style={{ marginTop: "300px" }}>
          <Spinner />
        </div>
      ) : (
        <Container>
          <Box>
            <Div>
              <span>🚫</span>
            </Div>
            <Title>No se pudo llevar a cabo la operacion</Title>
            <P>Lo sentimos mucho, intente nuevamente.</P>
            <Link to="/" className="link">
              <Btn>Volver</Btn>
            </Link>
          </Box>
        </Container>
      )}
    </>
  );
};

export default Error;
