import { useEffect, useState } from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { addProductFav, deleteProductFav } from "features/fav/favSlice";
import { currencyFormat } from "utils/data-conversion";
import { calcularDescuento } from "utils/descuento";

import AOS from 'aos';
import 'aos/dist/aos.css';
import { mobile } from "styles/responsive";

const Info = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* position: absolute;
  bottom: 0;
  right: 0; */
`;
const Container = styled.div`
  /* flex: 1; */
  /* flex: 1 280px/350px; */
  margin: 10px;
  min-width: 280px;
  height: 395px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};

  border: 1px solid ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.border.r4};
  /* -webkit-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.53);
  box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.53);
  position: relative; */

  /* box-shadow: 0 5px 12px -1px rgb(0 0 0 / 5%); */
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); */

  &:hover {
    /* -webkit-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.83);
    box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.83); */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  ${mobile({ margin: "10px 0",   width: "90%"})}
`;
const ImgContainer = styled.div`
  width: 100%; // 250px
  position: absolute;
  top: 0;
`;
const Image = styled.img`
  width: 100%;
  height: 225px;
  object-fit: contain;
  border-bottom: 0.5px solid ${props => props.theme.colors.border};
`;
const InfoContainer = styled.div`
  width: 90%;
  height: 148px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 59%;
`;
const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.h1`
  font-size: 16px;
  font-weight: bold;
`;
const Desc = styled.p`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
`;
const Price = styled.span`
  color: ${props => props.theme.colors.black};
  margin-top: 5px;
  font-weight: bold;
`;
const Icon = styled.button`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: ${props => props.theme.border.r4};
  padding: 10px;
  border: 2px solid #10C98F;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`;
const IconFav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
`;
const Links = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #10C98F;
  width: 100%;
`;
const ButtonFav = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  color: #000;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0); 
`;
const Del = styled.del`
  color: ${props => props.theme.colors.gray2};
  margin-left: 3px;
  font-size: 14px;
  font-weight: 500;
`;
const Descuento = styled.span`
  background-color: #e52727;
  color: #fff;
  padding: 3px 12px;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 10;
  border-radius: 30px;
  font-weight: bold;
`;

const Product = ({ item }) => {
  const [quantity, setQuantity] = useState(false);
  const dispatch = useDispatch();

  // ****** Agregar a favoritos ****** 
  const handleAddFav = () => {
    setQuantity(true);
    dispatch(addProductFav({ ...item }));
  };
  // ****** Eliminar de favoritos ******
  const handleDeleteFav = (id) => {
    setQuantity(false);
    dispatch(deleteProductFav({ id }));
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, [])
  

  return (
    <Container data-aos="zoom-in-up">
      {item.discount > 1 && <Descuento>-{item.discount}%</Descuento>}
      <ImgContainer>
        <Links to={`/producto/${item.slug}`}>
          <Image src={item.img?.[0]} loading="lazy" alt={item.img?.[0]} />
        </Links>
      </ImgContainer>

      <InfoContainer>
        <DetailsContainer>
          <Title>{item.title}</Title>
          <Desc>{item.desc}</Desc>
          {item.discount > 1 ? (
            <Price>
              {currencyFormat(calcularDescuento(item.price, item.discount))}{" "}
              <Del>{currencyFormat(item.price)}</Del>
            </Price>
          ) : (
            <Price>{currencyFormat(item.price)}</Price>
          )}
        </DetailsContainer>
        <Info>
          <IconFav>
            <ButtonFav
              aria-label="favorito"
              onClick={
                quantity ? () => handleDeleteFav(item.id) : handleAddFav
              }
            >
              {quantity ? (
                <Favorite style={{ width: "30px", color: "red" }} />
              ) : (
                <FavoriteBorder style={{ width: "30px", color: "gray" }} />
              )}
            </ButtonFav>
          </IconFav>
          <Icon>
            <Links to={`/producto/${item.slug}`}>
              Ver Producto
            </Links>
          </Icon>
        </Info>
      </InfoContainer>
    </Container>
  );
};

export default Product;
