import { createAsyncThunk } from '@reduxjs/toolkit';
import { publicRequest, userRequest } from 'config/clienteAxios';

export const editUser = createAsyncThunk('user/edit', async (usuario) => {
  const token = JSON.parse(localStorage.getItem('token_user'));
  console.log({ usuario, token });
  try {
    const user = await userRequest.put(`/usuarios/${usuario.id}`, usuario, {
      headers: { token: token },
    });
    return user.data.updatedUser;
  } catch (error) {
    console.log(error);
  }
});

export const loginUser = createAsyncThunk(
  'user/login',
  async (user, { rejectWithValue }) => {
    try {
      const res = await publicRequest.post('/auth/login', user);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
