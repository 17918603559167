import React from "react";

import styled from 'styled-components';

const Loader = styled.div`
  margin: ${(props) => props.margin || `200px auto`};
  /* margin: 200px auto; */
  border: 5px solid #EAF0F6;
  border-radius: 50%;
  border-top: 5px solid ${(props) => props.theme.colors.primary};
  width: 40px;
  height: 40px;
  animation: spinner 1s linear infinite;


  @keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const Spinner = (props) => {
  return (
    <Loader {...props} ></Loader>
  );
};

export default Spinner;



const LoaderCircle = styled.div`
  width: 50px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin: 0 auto;

  & div {
    background: #555;
    width: 10px;
    height: 10px;
    margin: 0 3px;
    border-radius: 50%;
    animation: animate 2s infinite linear;
    
    @keyframes animate {
      0%, 100% { opacity: 0 }
      50% { opacity: 1 }
    }
  }

  & div:nth-child(1) {
    animation-delay: 0.5s;
  }
  & div:nth-child(2) {
    animation-delay: 1s;
  }
  & div:nth-child(3) {
    animation-delay: 1.5s;
  }

`;

export const Spinner2 = () => {
  return (
    <LoaderCircle>
      <div></div>
      <div></div>
      <div></div>
    </LoaderCircle>
  );
};


const Loader2 = styled.div`
  /* margin: ${(props) => props.margin}; */
  margin: 200px auto;
  width: 40px;
  height: 40px;
  border: 5px solid ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  animation: spinner-1 0.8s linear infinite alternate, spinner-2 1.6s linear infinite;


  @keyframes spinner-1 {
   0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
   }
   12.5% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
   }
   25% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
   }
   50% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }
   62.5% {
      clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }
   75% {
      clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
   }
   100% {
      clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
   }
}

@keyframes spinner-2 {
   0% {
      transform: scaleY(1) rotate(0deg);
   }
   49.99% {
      transform: scaleY(1) rotate(135deg);
   }
   50% {
      transform: scaleY(-1) rotate(0deg);
   }
   100% {
      transform: scaleY(-1) rotate(-135deg);
   }
}
`;
export const Spinner3 = () => {
  return (
    <Loader2></Loader2>
  );
};