import { createSlice } from '@reduxjs/toolkit';
import { editUser, loginUser } from './userThunks';

const localStorageToken = (token, checkToken = false) => {
  if (checkToken) {
    localStorage.removeItem('token_user');
  } else {
    localStorage.setItem('token_user', JSON.stringify(token));
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
    isFetching: false,
    error: null,
  },
  reducers: {
    // ------------------ LOGIN DE USUARIOS ------------------
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
    },
    loginFailure: (state, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
    logout: (state) => {
      localStorageToken('', true);
      state.currentUser = null;
      state.isFetching = false;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });

    builder
      .addCase(loginUser.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        localStorageToken(action.payload.accessToken);
        state.isFetching = false;
        state.currentUser = action.payload.dataValues;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action.payload.message;
      });
  },
});

export const { loginStart, loginSuccess, loginFailure, logout } =
  userSlice.actions;
export default userSlice.reducer;
