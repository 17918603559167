import React, { useEffect, useState } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { alertDelete } from 'components/alerts/SweetAlert';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  Image,
  Span,
  Table,
  TableContainer,
  Title,
  TitleContainer,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { Link } from 'react-router-dom';
import { DeleteOutline, Edit, Visibility } from '@material-ui/icons';
import { userRequest } from 'config/clienteAxios';
import { DataGridTable } from 'utils/data-grid-table';
import { useSelector } from 'react-redux';

const UserList = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [data, setData] = useState(null);
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  const getUsers = async () => {
    const res = await userRequest.get('/usuarios', {
      headers: { token: accessToken },
    });
    setData(res.data.users);
  };

  const deleteUser = async (id) => {
    await userRequest.delete('/usuarios/' + id, {
      headers: { token: accessToken },
    });
    getUsers();
  };

  const handleDelete = (id) => {
    alertDelete(deleteUser, id, 'Producto eliminado');
  };

  useEffect(() => {
    getUsers();
  }, []); // data

  const columns = [
    // {
    //   field: '_id',
    //   headerName: 'ID',
    //   width: 180,
    // },
    {
      field: 'name',
      headerName: 'Nombre',
      width: 200,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Image
              src={
                params.row.img ||
                'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
              }
              alt=''
            />
            {params.row.name}
          </div>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
    },
    {
      field: 'address',
      headerName: 'Dirección',
      width: 180,
    },
    {
      field: 'tel',
      headerName: 'Teléfono',
      width: 140,
    },
    {
      field: 'postalCod',
      headerName: 'C. Postal',
      width: 140,
    },
    {
      field: 'action',
      headerName: 'Acciones',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={'/editUsuario/' + params.row.id}>
              <Button
                bgColor='alternative'
                fw={100}
                padding='5px 7px'
                margin='0 10px 0 0'
                color='#fff'
              >
                <Edit />
              </Button>
            </Link>
            <Link to={'/viewUsuario/' + params.row.id}>
              <Button
                bgColor='#888888'
                fw={100}
                padding='5px 7px'
                margin='0 10px 0 0'
                color='#fff'
              >
                <Visibility />
              </Button>
            </Link>
            {params.row.id !== currentUser?.id && (
              <Button
                bgColor='cancel'
                fw={100}
                padding='5px 7px'
                color='#fff'
                onClick={() => handleDelete(params.row.id)}
              >
                <DeleteOutline />
              </Button>
            )}
          </>
        );
      },
    },
  ];

  if (!data) {
    return <h1>Loading</h1>;
  }
  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <TitleContainer>
            <Title>
              Usuarios
              <Span>({data.length} usuarios)</Span>
            </Title>
            <Link to='/nuevoUsuario'>
              <Button
                w='80px'
                bgColor='secondary'
                color='#fff'
                fw={100}
                fs='16px'
                padding='5px'
              >
                Crear
              </Button>
            </Link>
          </TitleContainer>

          <TableContainer>
            <Table>
              <DataGridTable data={data} columns={columns} />
            </Table>
          </TableContainer>
        </Wrapper>
      </div>
    </>
  );
};

export default UserList;
