import styled from "styled-components";
import { mobile } from "styles/responsive";

export const Container = styled.div`
  min-height: 80vh;
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

export const Wrapper = styled.div`
  width: 800px;
  border-radius: 8px;
  -webkit-box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.53);
  box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.53);
  ${mobile({ height: "550px", margin: "0 20px 0" })}
`;

export const Title = styled.h3`
  margin: 20px;
  padding: 0 10px;
  font-size: 20px;
  background-color: #eee;
  font-weight: 600;
  display: flex;
  justify-content: center;
`;

export const Subtitle = styled.h3`
  margin: 20px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.gray3};
`;

export const Details = styled.div`
  display: flex;
  margin: 0 30px 20px;
  flex-direction: column;
  ${mobile({
    display: "flex",
    flexDirection: "column",
    padding: "0",
    marginBottom: "20px",
  })}
`;

export const Input = styled.div`
  font-size: 14px;
  margin: 0 0 10px;
`;

export const Span = styled.span`
  font-weight: bold;
`;

export const Btn = styled.span`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-right: 20px;
  cursor: pointer;
`;

export const Alerta = styled.span`
  margin: 20px;
  padding: 10px;
  font-size: 14px;
  background-color: #b30d0d;
  color: white;
  display: flex;
  justify-content: center;
  ${mobile({ textAlign: "center" })}
`;