import Swal from "sweetalert2";

export const alertSuccess = (title) => {
    Swal.fire({
        position: "top-end",
        icon: "success",
        title,
        showConfirmButton: false,
        timer: 1500,
    });
}
export const alertError = (title) => {
    Swal.fire({
        position: "top-end",
        icon: "error",
        title,
        showConfirmButton: false,
        timer: 1500,
    });
}
export const alertDelete = (funcDelete, id, msg) => {
    Swal.fire({
        title: '¿Está seguro?',
        text: "¡No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminarlo!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'Eliminado!',
                msg,
            'success'
            )
            funcDelete(id);
        }
      })
}
export const alertToast = (title) => {
    const Toast = Swal.mixin({
        toast: true,
        width: 400,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
  
      Toast.fire({
        icon: "success",
        title
      });
}