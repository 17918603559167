import { WhatsApp } from "@material-ui/icons";
import React, { useEffect } from "react";
import styled from "styled-components";
import { mobile } from "styles/responsive";
import Header from "../header/Header";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getInfo } from "api/apiCalls";

const Static = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
`;
const Boton = styled.a`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 30px;
  right: 30px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;

  &:hover {
    text-decoration: none;
    color: #25d366;
    background-color: #fff;
  }
  ${mobile({ right: "10px" })}
`;

const Layout = ({ title, desc = 'Pure', children }) => {

  const info = useSelector((state) => state.info.tienda);
  const dispatch = useDispatch();

  useEffect(() => {
    getInfo(dispatch);
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Pure | { title }</title>
        <meta name="description" content={ desc } />
      </Helmet>

      <Static>
        <Header />
      </Static>

      <Boton
        href={`https://api.whatsapp.com/send?phone=${info?.phone}`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="whatsapp"
      >
        <WhatsApp style={{ fontSize: "35", marginTop: "13px" }} />
      </Boton>

      <main>{children}</main>
    </>
  );
};

export default Layout;
