import {
  Dashboard,
  Person,
  Storefront,
  AttachMoney,
  Category,
  AddPhotoAlternate,
  Style,
  LoyaltyOutlined,
  DescriptionOutlined,
  Settings,
  MonetizationOnOutlined,
  Palette,
} from "@material-ui/icons";

export const NavigationPrincipal = [
  { name: "Home", path: "/" },
  { name: "Categorias", path: "/categorias" },
  { name: "Productos", path: "/lista-productos" },
  { name: "Ofertas", path: "/ofertas" },
  { name: "Nosotros", path: "/nosotros" },
  { name: "Contacto", path: "/contacto" },
];

export const NavigationAdmin = [
  {
    name: "Inicio",
    icon: <Dashboard style={{ fontSize: "20px" }} />,
    path: "/administrador",
  },
  {
    name: "Usuarios",
    icon: <Person style={{ fontSize: "20px" }} />,
    path: "/usuarios",
  },
  {
    name: "Productos",
    icon: <Storefront style={{ fontSize: "20px" }} />,
    path: "/productos",
  },
  {
    name: "Transacciones",
    icon: <AttachMoney style={{ fontSize: "20px" }} />,
    path: "/ordenes",
  },
  {
    name: "Categorias",
    icon: <Category style={{ fontSize: "20px" }} />,
    path: "/categorias",
  },
  {
    name: "Banner",
    icon: <AddPhotoAlternate style={{ fontSize: "20px" }} />,
    path: "/banner",
  },
  {
    name: "Cupones",
    icon: <Style style={{ fontSize: "20px" }} />,
    path: "/cupones",
  },
  {
    name: "Ofertas",
    icon: <LoyaltyOutlined style={{ fontSize: "20px" }} />,
    path: "/ofertas-productos",
  },
  {
    name: "Seccion Nosotros",
    icon: <DescriptionOutlined style={{ fontSize: "20px" }} />,
    path: "/info-nosotros",
  },
  {
    name: "Configuracion",
    icon: <Settings style={{ fontSize: "20px" }} />,
    path: "/configuracion",
  },
  {
    name: "Costos",
    icon: <MonetizationOnOutlined style={{ fontSize: "20px" }} />,
    path: "/costos",
  },
  {
    name: "Estilos",
    icon: <Palette style={{ fontSize: "20px" }} />,
    path: "/estilos",
  },
];
