import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  background-color: #95c2de;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Main = styled.div`
  width: 600px;
  height: 600px;
  background-color: #95c2de;
  position: relative;

  .numbers {
    display: flex;
  }

  .err {
    color: #ffffff;
    font-size: 11rem;
    position: absolute;
    left: 20%;
    top: 8%;
  }

  .far {
    position: absolute;
    font-size: 9.5rem;
    left: 41%;
    top: 17%;
    color: #ffffff;
    animation: rotation 3s infinite linear;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }

  .err2 {
    color: #ffffff;
    font-size: 11rem;
    position: absolute;
    left: 68%;
    top: 8%;
  }

  .msg {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 700;
    position: absolute;
    left: 16%;
    top: 45%;
    width: 75%;
    color: #000;

    p {
      color: #000;
    }

    a {
      text-decoration: none;
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 485px) {
    width: 300px;

    .msg {
      font-size: 1.2rem;
      width: 100%;
      left: 0;
      top: 45%;
    }

    .err {
      left: 0;
      font-size: 9rem;
    }
    .far {
      left: 30%;
      top: 15%;
      font-size: 8rem;
    }
    .err2 {
      font-size: 9rem;
      left: 70%;
    }
  }
`;