import { HelpOutline } from "@material-ui/icons";
import React from "react";
import { Container, Main } from "./NotFoundStyles";

const NotFound = () => {
  return (
    <Container>
      <Main>
        <div className="numbers">
          <div className="err">4</div>
          <span>
            <HelpOutline className="far" />
          </span>
          <div className="err2">4</div>
        </div>
        <div className="msg">
          Página no encontrada
          <p>
            Ir a <a href="/">Inicio</a> e intenta desde allí.
          </p>
        </div>
      </Main>
    </Container>
  );
};

export default NotFound;
