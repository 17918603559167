import { useEffect, useState } from "react";
import Spinner from "components/spinner/Spinner";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { limpiarCart } from "features/cart/cartSlice";
import { userRequest } from "config/clienteAxios";
import { Box, Btn, Container, Div, P, Title } from "./SuccessStyles";

const SuccessCard = () => {
  const [cargando, setCargando] = useState(false);

  const user = useSelector((state) => state.user.currentUser);
  const { _id } = user;

  const dispatch = useDispatch();

  useEffect(() => {
    const sendEmailOrder = async () => {
      try {
        await userRequest.get(`/ordenes/email/${_id}`);
      } catch (error) {}
    };
    sendEmailOrder();
  }, [_id]);

  const handleClearCart = () => {
    dispatch(limpiarCart());
  };

  useEffect(() => {
    setCargando(true);

    setTimeout(() => {
      setCargando(false);
    }, 2000);
  }, []);

  return (
    <>
      {cargando ? (
        <div style={{ marginTop: "300px" }}>
          <Spinner />
        </div>
      ) : (
        <Container>
          <Box>
            <Div>
              <i>✓</i>
            </Div>
            <Title>Operacion Exitosa</Title>
            <P>
              Muchas Gracias por su compra! En breve desde la administración de
              la Tienda se pondrán en contacto contigo para{" "}
              <strong>coordinar el pago</strong> y la
              <strong> entrega del producto</strong>.
            </P>
            <P>Dirigete a Mis Pedidos para ver tu N° de Pedido</P>
            <Link to="/" className="link">
              <Btn onClick={handleClearCart}>Volver</Btn>
            </Link>
          </Box>
        </Container>
      )}
    </>
  );
};

export default SuccessCard;
