import React, { useState } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  BoxButtons,
  BoxInput,
  Form,
  Input,
  Label,
  TextArea,
  Title,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { userRequest } from 'config/clienteAxios';
import { alertSuccess } from 'components/alerts/SweetAlert';
import { Link, useHistory } from 'react-router-dom';

const NewConfiguracion = () => {
  const [data, setData] = useState({});
  // const [cost, setCost] = useState({});

  const history = useHistory();
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  const handleInputChange = (e) => {
    setData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const producto = { ...data };

    try {
      const res = await userRequest.post(`/infotienda`, producto, {
        headers: { token: accessToken },
      });
      if (res.data.success) {
        alertSuccess(res.data.msg);
        history.push('/configuracion');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <Title>Ingresar Datos</Title>
          <Form>
            <BoxInput>
              <Label>Nombre Tienda</Label>
              <Input name='name' type='text' onChange={handleInputChange} />
            </BoxInput>
            <BoxInput>
              <Label>Descripcion</Label>
              <TextArea
                name='description'
                cols='30'
                rows='8'
                onChange={handleInputChange}
              ></TextArea>
            </BoxInput>
            <BoxInput>
              <Label>Teléfono</Label>
              <Input name='tel' type='tel' onChange={handleInputChange} />
            </BoxInput>
            <BoxInput>
              <Label>Teléfono Movil</Label>
              <Input name='phone' type='tel' onChange={handleInputChange} />
            </BoxInput>
            <BoxInput>
              <Label>Email</Label>
              <Input name='email' type='email' onChange={handleInputChange} />
            </BoxInput>
            <BoxInput>
              <Label>Dirección</Label>
              <Input name='address' type='text' onChange={handleInputChange} />
            </BoxInput>
            <BoxInput>
              <Label>Facebook</Label>
              <Input name='facebook' type='text' onChange={handleInputChange} />
            </BoxInput>
            <BoxInput>
              <Label>Instagram</Label>
              <Input
                name='instagram'
                type='text'
                onChange={handleInputChange}
              />
            </BoxInput>

            {/* <BoxInput>
              <Label  style={{ marginTop: "10px"}}>Costos</Label>
            </BoxInput>
            <BoxInput>
              <Label>Envio</Label>
              <Input
                name="envio"
                type="number"
                min={0}
                onChange={handleCostosChange}
              />
            </BoxInput>
            <BoxInput>
              <Label>Adicional</Label>
              <Input
                name="adicional"
                type="number"
                min={0}
                onChange={handleCostosChange}
              />
            </BoxInput>
            <BoxInput>
              <Label>Monto</Label>
              <Input
                name="monto"
                type="number"
                min={0}
                onChange={handleCostosChange}
              />
            </BoxInput> */}

            <BoxButtons>
              <Button
                color='#fff'
                padding='10px 15px'
                margin='10px 10px 0 0'
                onClick={handleFormSubmit}
              >
                Agregar
              </Button>
              <Link to='/configuracion' className='link'>
                <Button
                  bgColor='cancel'
                  color='#fff'
                  padding='10px 15px'
                  margin='10px 10px 0 0'
                >
                  Cancelar
                </Button>
              </Link>
            </BoxButtons>
          </Form>
        </Wrapper>
      </div>
    </>
  );
};

export default NewConfiguracion;
