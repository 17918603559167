import React, { useState } from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";
import { NavigationAdmin } from "utils/navigation";
import {
  Menu,
  Close,
  ArrowBack,
} from "@material-ui/icons";

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #eee;
  transition: all 0.3s ease-in;
  font-size: 0.9rem;
  &:hover {
    color: #e31c5c;
  }
  @media (max-width: 768px) {
    order: 1;
  }
`;
const Nav = styled.nav`
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
const Div = styled.div`
  padding: 10px;
  color: #eee;
  font-weight: 800;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    order: 1;
  }
`;
const MenuItem = styled.div`
  display: none;
  position: relative;
  @media (max-width: 768px) {
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    max-height: ${({ isOpen }) => (isOpen ? "600px" : "0")};
    transition: max-height 0.3s ease-in;
    width: 100%;
    order: 4;
    display: flex;
  }
`;
const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  font-size: 30px;
  cursor: pointer;
  @media (max-width: 768px) {
    display: flex;
    order: 2;
  }
`;

const Topbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Nav>
      <Div>
        <Link to="/" style={{ display: "grid", placeItems: "center" }}>
          <ArrowBack style={{ color: "#fff", marginRight: "10px" }} />
        </Link>
        <span>Dashboard</span>
      </Div>
      <Hamburger onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <Close style={{ color: "#fff", fontSize: "30px" }} />
        ) : (
          <Menu style={{ color: "#fff", fontSize: "30px" }} />
        )}
      </Hamburger>
      <MenuItem isOpen={isOpen}>
        {NavigationAdmin.map((item, index) => (
          <MenuLink to={item.path} key={index}>
            <div style={{ marginRight: "5px", display: "flex", alignItems: "center" }}>{item.icon}</div>
            <span>{item.name}</span>
          </MenuLink>
        ))}
      </MenuItem>
    </Nav>
  );
};

export default Topbar;