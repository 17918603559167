import { DataGrid } from '@material-ui/data-grid';
import { localeText } from './data-grid-traductor';

export const DataGridTable = ({ data, columns }) => {
  console.log({ data, columns });
  return (
    <DataGrid
      getRowId={(row) => row.id}
      rows={data}
      columns={columns}
      pageSize={8} // Determina el tamaño de la pagina
      rowsPerPageOptions={[8]} // Poder seleccionar cuantas filas apareceran en la tabla
      // checkboxSelection // Este muestra el cuadro se seleccion en cada fila
      disableSelectionOnClick //  Deshabilitar la selección de la fila al hacer clic
      localeText={localeText} // Para traducir la tabla a español
      // rowHeight={45} // Alto de una fila
      autoHeight // Cambie de tamaño de acuerdo con su contenido
      disableColumnSelector={true} //Para deshabilitar el selector de columnas
    />
  );
};
