import React, { useState } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  BoxButtons,
  BoxInput,
  Form,
  Input,
  Label,
  Title,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { Link, useHistory } from 'react-router-dom';
import { userRequest } from 'config/clienteAxios';
import { alertError, alertSuccess } from 'components/alerts/SweetAlert';

const NewCategory = () => {
  const [inputs, setInputs] = useState({});
  // const [talla, setTalla] = useState([]);

  const history = useHistory();
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  const handleInputChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  // const handleSize = (e) => {
  //   let arr = [];
  //   arr = e.target.value.split(",");
  //   const res = arr.map((c) => c.trim());
  //   setTalla(res);
  // };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // const dataCategory =  {...inputs, talla:  talla}
    const dataCategory = { ...inputs };

    if (Object.keys(inputs).length === 0) {
      alertError('Los campos son obligatorios');
      return;
    }
    // if(talla.length === 0) {
    //   alertError("Los campos son obligatorios")
    //   return;
    // }

    try {
      const newCategory = async () => {
        const { data } = await userRequest.post('/categorias', dataCategory, {
          headers: { token: accessToken },
        });
        if (data.success) {
          alertSuccess(data.msg);
          history.push('/categorias');
        } else {
          alertError(data.msg);
        }
      };
      newCategory();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <Title>Nueva Categoria</Title>
          <Form>
            <BoxInput>
              <Label>Nombre Categoria</Label>
              <Input
                name='name'
                type='text'
                onChange={handleInputChange}
                required
              />
            </BoxInput>
            {/* <BoxInput>
              <Label>Tallas</Label>
              <Input
                name="talla"
                type="text"
                onChange={handleSize}
                required
              />
            </BoxInput> */}
            <BoxButtons>
              <Button
                color='#fff'
                padding='10px 15px'
                margin='10px 10px 0 0'
                onClick={handleFormSubmit}
              >
                Crear
              </Button>
              <Link to='/categorias' className='link'>
                <Button
                  bgColor='cancel'
                  color='#fff'
                  padding='10px 15px'
                  margin='10px 10px 0 0'
                >
                  Cancelar
                </Button>
              </Link>
            </BoxButtons>
          </Form>
        </Wrapper>
      </div>
    </>
  );
};

export default NewCategory;
