import { useEffect, useState } from 'react';
import Products from 'components/product/Products';
import Footer from 'components/footer/Footer';

import styled from 'styled-components';
import { mobile } from 'styles/responsive';
import { useLocation } from 'react-router';
import { publicRequest } from 'config/clienteAxios';
import Layout from 'components/layouts/Layout';

const Title = styled.h1`
  margin: 80px 0 0;
  font-weight: 200;
  display: flex;
  justify-content: center;
`;
const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Filter = styled.div`
  margin: 20px;
  ${mobile({ width: '0 20px', display: 'flex', flexDirection: 'column' })}
`;
const FilterText = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
  ${mobile({ marginRight: '0' })}
`;
const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
  border-radius: 10px;
  ${mobile({ margin: '10px 0' })}
`;
const Option = styled.option``;

const ProductList = () => {
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  const location = useLocation();
  // const cat = location.pathname.split('/')[2];
  const slug = location.pathname.split('/')[2];

  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState('nuevo');
  const [catSlug, setCatSlug] = useState({});
  // const [categorias, setCategorias] = useState([]);

  const handleFilters = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const getCatSlug = async () => {
    const res = await publicRequest.get('/categorias/' + slug);
    console.log(res);
    setCatSlug(res.data);
  };

  // const getCat = async () => {
  //   const res = await publicRequest.get("/categorias");
  //   setCategorias(res.data);
  // };

  useEffect(() => {
    // getCat();
    getCatSlug();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { name } = catSlug;

  return (
    <Layout title='Categorias'>
      <Title>{name}</Title>
      <FilterContainer>
        {/* <Filter>
          <FilterText>Filtros:</FilterText>
          <Select name="tallas" onChange={handleFilters}>
            <Option>Marcas</Option>
            { catSlug && categorias.map(i => {
              return i.name === name && (
                i.talla?.map((t, index) => (
                  <Option value={t} key={index} >{t}</Option>
                ))
              )
            })}
          </Select>
        </Filter> */}
        <Filter>
          <FilterText>Ordenar:</FilterText>
          <Select onChange={(e) => setSort(e.target.value)}>
            <Option value='nuevo'>Lo mas nuevo</Option>
            <Option value='asc'>Precio más bajo</Option>
            <Option value='desc'>Precio más alto</Option>
          </Select>
        </Filter>
      </FilterContainer>

      {/* COMPONENTE PRODUCTOS
        Pasamos por props las categorias, filtros, y el orden.
      */}
      <Products cat={name} filters={filters} sort={sort} />
      <Footer />
    </Layout>
  );
};

export default ProductList;
