import React from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import {
  BoxIcon,
  Form,
  Label,
  Span,
  Title,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { userRequest } from 'config/clienteAxios';
import { useState } from 'react';
import {
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  RoomOutlined,
  LocalPostOffice,
} from '@material-ui/icons';

const UserView = () => {
  const location = useLocation();
  const productId = location.pathname.split('/')[2];
  const accessToken = JSON.parse(localStorage.getItem('token_user'));
  const [user, setUser] = useState({});

  const { name, lastName, email, address, tel, poblac, prov, postalCod } = user;

  useEffect(() => {
    const getUser = async () => {
      const { data } = await userRequest.get(`/usuarios/find/${productId}`, {
        headers: { token: accessToken },
      });
      setUser(data.data);
    };
    getUser();
  }, [productId]);

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <Title>Informacion del Usuario</Title>
          <Form>
            <div style={{ marginTop: '20px' }}>
              <Label>Detalles de la cuenta</Label>
              <BoxIcon>
                <PermIdentity style={{ fontSize: '18px' }} />
                <Span>{name}</Span>
              </BoxIcon>
              <BoxIcon>
                <PermIdentity style={{ fontSize: '18px' }} />
                <Span>{lastName}</Span>
              </BoxIcon>

              <Label>Detalles de contacto</Label>
              <BoxIcon>
                <PhoneAndroid style={{ fontSize: '18px' }} />
                <Span>{tel}</Span>
              </BoxIcon>
              <BoxIcon>
                <MailOutline style={{ fontSize: '18px' }} />
                <Span>{email}</Span>
              </BoxIcon>
              <BoxIcon>
                <RoomOutlined style={{ fontSize: '18px' }} />
                <Span>{address}</Span>
              </BoxIcon>
              <BoxIcon>
                <LocationSearching style={{ fontSize: '18px' }} />
                <Span>{poblac}</Span>
              </BoxIcon>
              <BoxIcon>
                <LocationSearching style={{ fontSize: '18px' }} />
                <Span>{prov}</Span>
              </BoxIcon>
              <BoxIcon>
                <LocalPostOffice style={{ fontSize: '18px' }} />
                <Span>{postalCod}</Span>
              </BoxIcon>
            </div>
          </Form>
        </Wrapper>
      </div>
    </>
  );
};

export default UserView;
