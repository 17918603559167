import React, { useEffect } from 'react';

import styled from 'styled-components';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination, Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/modules/effect-fade/effect-fade';
import 'swiper/modules/navigation/navigation';
import 'swiper/modules/pagination/pagination';

import '../banner/carrousel.css';
import { useDispatch, useSelector } from 'react-redux';
import Product from 'components/product/Product';
import { getProducts } from 'api/apiCalls';

const Container = styled.div`
  margin: 0 40px;

  .swiper {
    width: 100%;
    height: 400px;
    margin: 20px 0 30px;
  }
`;
const Box = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  img {
    height: 225px;
    object-fit: contain;
  }
`;

const ProductsList = ({ productId, category }) => {
  const dispatch = useDispatch();
  const productsState = useSelector((state) => state.product.products);
  const products = productsState.products;
  console.log(productsState);

  useEffect(() => {
    console.log('Hola');
    getProducts(dispatch);
  }, []);

  let productFilter =
    products?.filter((i) => i.categories === category && i.id !== productId) ||
    [];
  return (
    <Container>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        breakpoints={{
          375: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          485: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
          1204: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1380: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
      >
        {productFilter.map((item, index) => {
          return (
            <SwiperSlide key={index} style={{ height: '400px', margin: '0' }}>
              <Box>
                <Product item={item} key={item.id} />
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
};

export default ProductsList;
