import { css } from 'styled-components';

export const mobile = (props) => {
  return css`
    @media only screen and (max-width: 485px) {
      ${props}
    }
  `;
};
export const mobileMd = (props) => {
  return css`
    @media only screen and (max-width: 768px) {
      ${props}
    }
  `;
};

export const mobileLg = (props) => {
  return css`
    @media only screen and (max-width: 992px) {
      ${props}
    }
  `;
};