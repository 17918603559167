import React from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import Dashboard from 'pages/administrador/dashboard/Dashboard';
import styled from 'styled-components';

const Static = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
`;

const Administrador = () => {
  return (
    <>
    <Static>
      <Topbar />
    </Static>
      <div className="container">
        <Sidebar />
        <Dashboard />
      </div>
    </> 
  );
}

export default Administrador
