import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { userRequest } from "config/clienteAxios";
import { Link } from "react-router-dom";
import { currencyFormat } from "utils/data-conversion";
import { useSelector } from "react-redux";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  withStyles
} from "@material-ui/core";
import Layout from "components/layouts/Layout";

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },

  }))(TableCell);

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

const Container = styled.div`
    margin-top: 70px;
    padding: 10px;
`;

const Profile = () => {
  const [orders, setOrders] = useState([]);

  // const location = useLocation();
  // const id = location.pathname.split("/")[2];

  const user = useSelector((state) => state.user.currentUser);
  const id = user._id;

  const classes = useStyles();

  useEffect(() => {
    const getOrdersUser = async () => {
      try {
        const res = await userRequest.get(`/ordenes/find/${id}`);
        setOrders(res.data);
      } catch (error) {}
    };
    getOrdersUser();
  }, [id]);

  return (
    <Layout title='Ordenes de Compra'>
      <Container>
      <TableContainer className={classes.tablew}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>N° Pedido</StyledTableCell>
              <StyledTableCell align="left">Fecha</StyledTableCell>
              <StyledTableCell align="left">Hora</StyledTableCell>
              <StyledTableCell align="left">Estado</StyledTableCell>
              <StyledTableCell align="left">Total</StyledTableCell>
              <StyledTableCell align="left"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.map((orden, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {orden.numPedido}
                </TableCell>
                <TableCell align="left">{orden.fecha}</TableCell>
                <TableCell align="left">{orden.hora}</TableCell>
                <TableCell align="left">{orden.status}</TableCell>
                <TableCell align="left">{currencyFormat(orden.total)}</TableCell>
                <TableCell align="left">
                  <Link to={"/viewPedido/" + orden._id} style={{ color: "blue", textDecoration: "underline" }}>Ver detalle</Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Container>
    </Layout>
  );
};

export default Profile;
