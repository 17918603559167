import { useState } from 'react';

import { publicRequest, userRequest } from 'config/clienteAxios';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import {
  ArrowBack,
  EmailOutlined,
  PersonOutline,
  PhoneAndroid,
  RoomOutlined,
} from '@material-ui/icons';
import {
  Alerta,
  BoxInput,
  Button,
  Container,
  Form,
  Icon,
  Input,
  Title,
  Wrapper,
} from './UserInviteStyles';

const UserInvite = () => {
  const cart = useSelector((state) => state.cart);
  const cartProduct = useSelector((state) => state.cart.products);
  const dataOrder = { productos: cartProduct };
  const [cargando, setCargando] = useState(false);
  // const [pedido, setPedido] = useState(0);
  // const [importe, setImporte] = useState(0);
  const [tarjeta, setTarjeta] = useState(false);

  const location = useLocation();
  const { formaEnvio, formaPago, envio, adicional, monto } = location.state; // Estos datos vienen del boton LINK
  // console.log(dataOrder, formaEnvio, formaPago);

  const history = useHistory();

  // Formulario y validacion con Formik y Yup
  const formik = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      dni: '',
      email: '',
      address: '',
      tel: '',
      city: '',
      prov: '',
      codPostal: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[aA-zZ\s]+$/, 'Solo se permiten letras para este campo.')
        .min(3, 'Tu nombre es demasiado corto.')
        .required('El campo es requerido.'),
      lastName: Yup.string()
        .matches(/^[aA-zZ\s]+$/, 'Solo se permiten letras para este campo.')
        .min(3, 'Tu apellido es demasiado corto.')
        .required('El campo es requerido.'),
      dni: Yup.string()
        .min(8, 'El dni debe contener al menos 8 digitos.')
        .required('El campo es requerido'),
      email: Yup.string()
        .email('El email no es valido')
        .required('El campo es requerido.'),
      address: Yup.string()
        .min(3, 'Tu direccion es demasiado corta.')
        .max(50, 'Debe tener como máximo 50 caracteres.')
        .required('El campo es requerido.'),
      tel: Yup.string()
        .matches(/^[0-9\s]+$/, 'Solo se permiten numeros para este campo.')
        .min(9, 'El telefono debe contener al menos 9 digitos.')
        .required('El campo es requerido'),
      city: Yup.string().required('El campo es requerido.'),
      prov: Yup.string().required('El campo es requerido.'),
      codPostal: Yup.string()
        .matches(/^[0-9\s]+$/, 'Solo se permiten numeros para este campo.')
        .max(5, 'Debe tener como máximo 5 caracteres.')
        .required('El campo es requerido.'),
    }),
    onSubmit: (valores, { resetForm }) => {
      setCargando(true);
      // console.log(valores);
      userInvite(valores);
    },
  });

  const userInvite = async (dataUser) => {
    try {
      console.log(dataUser);
      console.log(cartProduct);
      // Usuario INVITADO
      if (cartProduct.length > 0 && formaPago && formaEnvio) {
        let status = 'Pendiente';
        let des = 0;

        const resultado = await publicRequest.post(`/ordenes/${status}`, {
          dataUser,
          dataOrder,
          des,
          paymentForm: formaPago,
          shipmentForm: formaEnvio,
          shipment: envio,
          additional: adicional,
          amount: monto,
        });
        console.log(resultado.data);

        const ord = resultado.data.newOrder;
        if (
          ord.paymentForm === 'transferencia' ||
          ord.paymentForm === 'contrareembolso'
        ) {
          history.push('/success-compra');
        } else {
          const res = await userRequest.post('/checkout/pago', {
            productos: cart.products,
            des,
            formaPago,
            formaEnvio,
            envio: envio,
            adicional,
            monto,
          });
          const { init_point } = res.data;
          window.location.href = `${init_point}`;
          // setPedido(ord.numPedido);
          // setImporte(CambioInteger(ord.total));
          setTarjeta(true);
        }
      }
    } catch (error) {
      setCargando(false);
      console.log(error);
    }
  };

  return (
    <Container>
      <Wrapper>
        <Link to='/carrito' className='link'>
          <ArrowBack />
        </Link>
        <Title>Identificación</Title>

        <Form onSubmit={formik.handleSubmit}>
          <BoxInput>
            <Icon>
              <PersonOutline />
            </Icon>
            <Input
              type='text'
              name='name'
              placeholder='Nombre'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.name && formik.errors.name ? (
            <Alerta>{formik.errors.name}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <PersonOutline />
            </Icon>
            <Input
              type='text'
              name='lastName'
              placeholder='Apellidos'
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.lastName && formik.errors.lastName ? (
            <Alerta>{formik.errors.lastName}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <PersonOutline />
            </Icon>
            <Input
              type='text'
              name='dni'
              placeholder='Ingrese su DNI'
              value={formik.values.dni}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.dni && formik.errors.dni ? (
            <Alerta>{formik.errors.dni}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <EmailOutlined />
            </Icon>
            <Input
              type='email'
              name='email'
              placeholder='Correo electrónico'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.email && formik.errors.email ? (
            <Alerta>{formik.errors.email}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <RoomOutlined />
            </Icon>
            <Input
              type='text'
              name='address'
              placeholder='Dirección'
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.address && formik.errors.address ? (
            <Alerta>{formik.errors.address}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <PhoneAndroid />
            </Icon>
            <Input
              type='tel'
              name='tel'
              placeholder='Teléfono (cod. área + número)'
              value={formik.values.tel}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.tel && formik.errors.tel ? (
            <Alerta>{formik.errors.tel}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <RoomOutlined />
            </Icon>
            <Input
              type='text'
              name='city'
              placeholder='Ciudad'
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.city && formik.errors.city ? (
            <Alerta>{formik.errors.city}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <RoomOutlined />
            </Icon>
            <Input
              type='text'
              name='prov'
              placeholder='Provincia'
              value={formik.values.prov}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.prov && formik.errors.prov ? (
            <Alerta>{formik.errors.prov}</Alerta>
          ) : null}

          <BoxInput>
            <Icon>
              <RoomOutlined />
            </Icon>
            <Input
              type='text'
              name='codPostal'
              placeholder='Código Postal'
              value={formik.values.codPostal}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.codPostal && formik.errors.codPostal ? (
            <Alerta>{formik.errors.codPostal}</Alerta>
          ) : null}
          <Button
            type='submit'
            disabled={cargando}
            style={cargando ? { opacity: 0.7 } : {}}
          >
            {cargando ? 'PROCESANDO...' : 'CONTINUAR'}
          </Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default UserInvite;
