import React, { useEffect, useState } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import {
  alertDelete,
  alertError,
  alertSuccess,
} from 'components/alerts/SweetAlert';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  BoxButtons,
  BoxInput,
  Form,
  Input,
  Label,
  Select,
  Title,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { userRequest } from 'config/clienteAxios';
import { Format } from 'utils/data-conversion';
import { Backspace } from '@material-ui/icons';

const Cupones = () => {
  const [cupon, setCupon] = useState({
    name_cupon: '',
    type: '',
    descount: '',
    expiration_date: '',
  });

  const [dataCupon, setDataCupon] = useState([]);

  let fechaActual = new Date().toISOString().split('T')[0];
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  const handleChangeCupon = (e) => {
    setCupon((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const getCupones = async () => {
    try {
      const res = await userRequest.get('/cupon', {
        headers: { token: accessToken },
      });
      setDataCupon(res.data.coupons);
    } catch (error) {}
  };

  const deleteCupon = async (id) => {
    await userRequest.delete(`/cupon/${id}`, {
      headers: { token: accessToken },
    });
    getCupones();
  };

  const handleDeleteCupon = async (id) => {
    try {
      alertDelete(deleteCupon, id, '¡Cupón eliminado!');
    } catch (error) {}
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { name_cupon, type, descount, expiration_date } = cupon;

    if ([name_cupon, type, descount, expiration_date].includes('')) {
      alertError('Todos los campos son obligatorios');
      return;
    }

    const cup = { ...cupon };

    try {
      const newCupon = async () => {
        console.log(cup);
        const { data } = await userRequest.post('/cupon', cup, {
          headers: { token: accessToken },
        });
        if (data.success) {
          alertSuccess(data.msg);
        } else {
          alertError(data.msg);
        }
        getCupones();
      };
      newCupon();
    } catch (error) {}
    e.target.reset();
  };

  useEffect(() => {
    getCupones();
  }, [cupon]);

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <Title>Generar Cupón</Title>
          <Form onSubmit={handleFormSubmit}>
            <BoxInput>
              <Label>Nombre</Label>
              <Input
                name='name_cupon'
                type='text'
                onChange={handleChangeCupon}
              />
            </BoxInput>
            <BoxInput>
              <Label>Tipo Descuento</Label>
              <Select
                name='type'
                style={{ height: '40px', padding: '5px' }}
                onChange={handleChangeCupon}
              >
                <option>Seleccionar</option>
                <option value='porcentaje'>Porcentaje</option>
                <option value='importe'>Importe</option>
              </Select>
            </BoxInput>
            <BoxInput>
              <Label>Descuento</Label>
              <Input
                name='descount'
                type='number'
                onChange={handleChangeCupon}
                min='0'
              />
            </BoxInput>
            <BoxInput>
              <Label>Fecha de vencimiento</Label>
              <Input
                name='expiration_date'
                type='date'
                onChange={handleChangeCupon}
                min={fechaActual}
              />
            </BoxInput>
            <BoxButtons>
              <Button
                type='submit'
                color='#fff'
                padding='10px 15px'
                margin='10px 0'
              >
                Crear Cupón
              </Button>
            </BoxButtons>
            {dataCupon.length > 0 && (
              <div style={{ margin: '30px 0 10px' }}>
                <b>Cupones</b>
              </div>
            )}
            {dataCupon.map((item, index) => (
              <div key={item.id} style={{ margin: '5px 0 10px' }}>
                <b>{item.name_cupon}</b> - {item.type}({item.descount}) -{' '}
                {Format(item.expiration_date)}{' '}
                <span onClick={() => handleDeleteCupon(item.id)}>
                  <Backspace
                    style={{
                      fontSize: '14px',
                      marginLeft: '3px',
                      cursor: 'pointer',
                    }}
                  />{' '}
                </span>
              </div>
            ))}
          </Form>
        </Wrapper>
      </div>
    </>
  );
};

export default Cupones;
