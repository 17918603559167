import { useState } from "react";
import { publicRequest } from "config/clienteAxios";
import {
  ArrowBack,
  AlternateEmail,
  CheckCircleOutline,
  ErrorOutline,
} from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Alerta,
  BoxInput,
  Button,
  Container,
  Error,
  Form,
  Icon,
  Input,
  Links,
  Subtitle,
  Success,
  Title,
  Wrapper,
} from "./ForgotPasswordStyles";

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(true);
  const [msg, setMsg] = useState("");

  // Formulario y validacion con Formik y Yup
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("El email no es valido")
        .required("El email es obligatorio"),
    }),
    onSubmit: (valores, { resetForm }) => {
      // console.log(valores);
      forgotPassword(valores);
      resetForm({ valores: "" }); // Limpiar el formulario
    },
  });

  const forgotPassword = (inputs) => {
    const forgotPass = async () => {
      const res = await publicRequest.post("/auth/forgotPassword", inputs);
      setSuccess(res.data.success);
      setError(res.data.success);
      setMsg(res.data.msg);
    };
    forgotPass();

    setTimeout(() => {
      setSuccess(false);
      setError(true);
    }, 4000);
  };

  return (
    <Container>
      <Wrapper>
        <Links to="/iniciar-sesion" className="link">
          <ArrowBack />
        </Links>
        <Title>Recuperar contraseña</Title>
        <Subtitle>
          Por favor ingrese su dirección de correo electrónico y le enviaremos
          un enlace para restablecer la contraseña.
        </Subtitle>

        {success && (
          <Success>
            {" "}
            <CheckCircleOutline style={{ marginRight: "10px" }} /> {msg}
          </Success>
        )}
        {!error && (
          <Error>
            {" "}
            <ErrorOutline style={{ marginRight: "10px" }} /> {msg}
          </Error>
        )}

        <Form onSubmit={formik.handleSubmit}>
          <BoxInput>
            <Icon>
              <AlternateEmail />
            </Icon>
            <Input
              type="email"
              id="email"
              placeholder="Introduce tu correo electrónico"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </BoxInput>
          {formik.touched.email && formik.errors.email ? (
            <Alerta>{formik.errors.email}</Alerta>
          ) : null}

          <Button type="submit">Enviar</Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default ForgotPassword;
