export const MostrarDescuento = (total, data) => {
  if (data) {
    const { type, descount } = data;
    if (type === 'porcentaje') {
      const res = total * descount;
      return res;
    }
    if (type === 'importe') {
      const res = descount;
      return res;
    }
  }
  return 0;
};

export const calcularDescuento = (total, descuento) => {
  if (descuento > 0) {
    const des = (total * descuento) / 100;
    const res = total - des;
    return res;
  }
  return total;
};
