import React, { useEffect, useState } from "react";
import Topbar from "components/administrador/topbar/Topbar";
import { Button } from "styles/GlobalComponents/ButtonStyles";
import Sidebar from "components/administrador/sidebar/Sidebar";
import {
  BoxButtons,
  BoxInput,
  Form,
  Input,
  Label,
  TextArea,
  Title,
  Wrapper,
} from "styles/GlobalComponents/FormStyles";
import styled from 'styled-components';

import { Link, useLocation, useHistory } from "react-router-dom";
import { userRequest } from "config/clienteAxios";
import { Publish } from "@material-ui/icons";

const Div = styled.div`
  display: flex;
  align-items: center;

  .productUploadImg{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
  }
`;

const InfoNosotrosEdit = () => {
  const [data, setData] = useState({
    title: "",
    desc: "",
    img: "",
  });

  const [file, setFile] = useState(null);

  const location = useLocation();
  const infoId = location.pathname.split("/")[3];
  const history = useHistory();

  const handleInputChange = (e) => {
    setData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const getData = async () => {
    const res = await userRequest.get(`/infotienda/nosotros/${infoId}`);
    setData(res.data);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { title, desc } = data;

    let formData = new FormData();

    formData.append("title", title);
    formData.append("desc", desc);

    for (let index = 0; index <= file?.length; index++) {
      formData.append("image", file[index]);
    }

    try {
      const updateInfo = async () => {
        await userRequest.put(`/infotienda/nosotros/${infoId}`, formData);
      };
      updateInfo();
      getData();
      history.push("/info-nosotros");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Topbar />
      <div className="container">
        <Sidebar />
        <Wrapper>
          <Title>Editar</Title>
          <Form onSubmit={handleFormSubmit}>
            <BoxInput>
              <Label>Imagen</Label>
              <Div
                style={{ marginBottom: "10px" }}
              >
                <img
                  src={data.img}
                  alt=""
                  className="productUploadImg"
                />
                <Label htmlFor="file">
                  <Publish style={{ cursor: "pointer" }} />
                </Label>
                <Input
                  type="file"
                  id="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setFile(e.target.files)}
                  style={{ display: "none" }}
                />
              </Div>
            </BoxInput>

            <BoxInput>
              <Label>Titulo</Label>
              <Input
                type="text"
                name="title"
                placeholder="Ingrese un titulo"
                style={{ marginBottom: "10px" }}
                value={data.title}
                onChange={handleInputChange}
              />
            </BoxInput>

            <BoxInput>
              <Label>Descripcion</Label>
              <TextArea
                name="desc"
                id="desc"
                cols="31"
                rows="8"
                placeholder="Ingrese una descripcion"
                value={data.desc}
                onChange={handleInputChange}
              ></TextArea>
            </BoxInput>

            <BoxButtons>
            <Button
                type="submit"
                color="#fff"
                padding="10px 15px"
                margin="10px 10px 0 0"
              >
                Actualizar
              </Button>
              <Link to="/info-nosotros" className="link">
                <Button
                  bgColor="cancel"
                  color="#fff"
                  padding="10px 15px"
                  margin="10px 10px 0 0"
                >
                  Cancelar
                </Button>
              </Link>
            </BoxButtons>
          </Form>
        </Wrapper>
      </div>
    </>
  );
};

export default InfoNosotrosEdit;
