import React, { useEffect, useMemo, useState } from 'react';
import FeaturedInfo from 'components/administrador/featuredInfo/FeaturedInfo';
import Chart from 'components/administrador/chart/Chart';
import WidgetSm from 'components/administrador/widgetSm/WidgetSm';
import WidgetLg from 'components/administrador/widgetLg/WidgetLg';
import styled from 'styled-components';

import { userRequest } from 'config/clienteAxios';

const Container = styled.div`
  flex: 4;
  padding: 10px 0;
  background: #fff;
`;
const Div = styled.div`
  display: flex;
  margin: 20px;

  @media (max-width: 485px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
`;

const Dashboard = () => {
  const [userStats, setUserStats] = useState([]);
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  const MONTHS = useMemo(
    () => [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
    []
  );

  useEffect(() => {
    const getStats = async () => {
      try {
        const res = await userRequest.get('usuarios/estadisticas', {
          headers: { token: accessToken },
        });
        res.data.map((item) =>
          setUserStats((prev) => [
            { name: MONTHS[item._id - 1], 'Usuario Activo': item.total },
          ])
        );
      } catch (error) {}
    };
    getStats();
  }, [MONTHS, accessToken]);

  return (
    <>
      <Container>
        <FeaturedInfo />
        <Chart
          data={userStats}
          title='Usuarios Analitics'
          grid
          dataKey='Usuario Activo'
        />
        <Div>
          <WidgetSm />
          <WidgetLg />
        </Div>
      </Container>
    </>
  );
};

export default Dashboard;
