import styled from "styled-components";
import { mobile } from "styles/responsive";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #fafafa;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

`;
export const Wrapper = styled.div`
  width: 30%;
  padding: 20px;
  margin: 20px 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.25);
  ${mobile({ width: "75%" })}
`;
export const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
export const Input = styled.input`
  flex: 1;
  min-width: 40%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  outline: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
export const Button = styled.button`
  width: 100%;
  border: none;
  padding: 15px 20px;
  background-color: #e31c5c;
  color: white;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 10px;
  margin: 20px auto 10px;
`;
export const Alerta = styled.div`
  color: red;
  font-size: 12px;
  margin-left: 10px;
`;
export const BoxInput = styled.div`
  display: flex;
  border: 2px solid #eee;
  border-radius: 10px;
  margin-top: 10px;
`;
export const Icon = styled.i`
  color: gray;
  display: flex;
  align-items: center;
  margin: 0 5px;
`;