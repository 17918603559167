import styled from "styled-components";
import { mobile, mobileLg, mobileMd } from "styles/responsive";

export const Wrapper = styled.div`
  margin-top: 70px;
  padding: 50px;
  display: flex;
  ${mobileMd({ padding: "10px", flexDirection: "column" })}
`;
export const FormContainer = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
`;
export const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const Label = styled.label`
  width: 100%;
  margin-top: 5px;
`;
export const Input = styled.input`
  padding: 10px;
  border: 1px solid gray;
  border-radius: 10px;
`;
export const TextArea = styled.textarea`
  height: 100px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 10px;
`;
export const Button = styled.button`
  background-color: #c23c3c;
  padding: 15px;
  border: none;
  color: #fff;
  font-weight: bold;
  letter-spacing: 3px;
  border-radius: 10px;
  cursor: pointer;
`;export const Iframe = styled.iframe`
  width: 100%;
  height: 310px;
  border: 0;
`;export const InfoContainer = styled.div`
  flex: 1;
  padding: 0px 50px;
  ${mobileLg({ padding: "0 0 0 20px" })}
  ${mobileMd({ marginTop: "40px", padding: "10px" })}
  ${mobile({ padding: "10px" })}
`;
export const Title = styled.h1`
  font-weight: 200;
`;
export const Desc = styled.div`
  margin: 0 0px 20px;
`;
export const ContactItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;
export const Alerta = styled.div`
  color: red;
  font-size: 12px;
  margin-left: 10px;
`;