import React, { useEffect } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  Span,
  Table,
  TableContainer,
  Title,
  TitleContainer,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { Link } from 'react-router-dom';
import { DeleteOutline, Edit } from '@material-ui/icons';
import { userRequest } from 'config/clienteAxios';
import { alertDelete } from 'components/alerts/SweetAlert';
import { DataGridTable } from 'utils/data-grid-table';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from 'api/apiCalls';

const CategoryList = () => {
  const categories = useSelector((state) => state.category.categories);
  const dispatch = useDispatch();
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  // const [data, setData] = useState([]);
  // const getCategories = async () => {
  //   const res = await userRequest.get("/categorias");
  //   setData(res.data);
  // };
  // useEffect(() => {
  //   getCategories();
  // }, []);

  const deleteCategoryId = async (id) => {
    await userRequest.delete(`/categorias/${id}`, {
      headers: { token: accessToken },
    });
    getCategories(dispatch);
  };

  const handleDeleteCategory = (id) => {
    alertDelete(deleteCategoryId, id, '¡Categoria eliminada!');
  };

  useEffect(() => {
    getCategories(dispatch);
  }, [dispatch]);

  const columns = [
    // { field: "_id", headerName: "ID", width: 200 },
    { field: 'name', headerName: 'Nombre', width: 200 },
    // { field: "talla", headerName: "Tallas", width: 200 },
    {
      field: 'action',
      headerName: 'Acciones',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={'/editCategoria/' + params.row.id}>
              <Button
                bgColor='alternative'
                padding='5px 7px'
                margin='0 10px 0 0'
                color='#fff'
              >
                <Edit />
              </Button>
            </Link>

            <Button
              bgColor='cancel'
              padding='5px 7px'
              margin='0 10px 0 0'
              color='#fff'
              onClick={() => handleDeleteCategory(params.row.id)}
            >
              <DeleteOutline />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <TitleContainer>
            <Title>
              Categorias
              <Span>({categories.length} categorias)</Span>
            </Title>
            <Link to='/nuevaCategoria'>
              <Button
                w='80px'
                bgColor='secondary'
                color='#fff'
                fw={100}
                fs='16px'
                padding='5px'
              >
                Crear
              </Button>
            </Link>
          </TitleContainer>

          <TableContainer>
            <Table>
              <DataGridTable data={categories} columns={columns} />
            </Table>
          </TableContainer>
        </Wrapper>
      </div>
    </>
  );
};

export default CategoryList;
