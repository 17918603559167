import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import {
  alertDelete,
  alertError,
  alertSuccess,
} from 'components/alerts/SweetAlert';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  BoxButtons,
  BoxInput,
  Form,
  Input,
  Label,
  Title,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { publicRequest, userRequest } from 'config/clienteAxios';
import { HighlightOff } from '@material-ui/icons';
import { mobile, mobileLg } from 'styles/responsive';

const Box = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  ${mobileLg({ gridTemplateColumns: 'repeat(2, 1fr)' })}
  ${mobile({ gridTemplateColumns: 'repeat(1, 1fr)' })}
`;
const BoxImage = styled.div`
  width: 200px;
  height: 200px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 1px solid #eee;
    margin: 0 10px;
  }

  .del {
    position: absolute;
    top: 5px;
    right: -10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .edit {
    position: absolute;
    top: 5px;
    left: 17px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    padding: 3px;
    border: 1px solid gray;
    border-radius: 10px;
  }

  ${mobile({ width: '80%', margin: '0 20px' })}
`;

const Banner = () => {
  const [file, setFile] = useState(null);
  const [img, setImg] = useState([]);
  const accessToken = JSON.parse(localStorage.getItem('token_user'));

  const getImages = async () => {
    const res = await publicRequest.get('/banner', {
      headers: { token: accessToken },
    });
    setImg(res.data);
  };

  const deleteImage = async (id) => {
    await userRequest.delete(`/banner/${id}`, {
      headers: { token: accessToken },
    });
    getImages();
  };

  const handleChangeStatus = async (id, status) => {
    await userRequest.put(
      `/banner/${id}`,
      { status: !status },
      { headers: { token: accessToken } }
    );
    getImages();
  };

  const handleDeleteImage = async (id) => {
    alertDelete(deleteImage, id, 'Imagen eliminada');
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let formData = new FormData();

    for (let index = 0; index < file.length; index++) {
      formData.append('image', file[index]);
    }

    try {
      await userRequest.post('/banner', formData, {
        headers: { token: accessToken },
      });

      alertSuccess('Imagen agregada!');
      getImages();
    } catch (error) {
      alertError('La imagen supera los 2mb');
    }
    setFile(null);
    e.target.reset();
  };

  useEffect(() => {
    getImages();
  }, []);

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <Title>Imagenes del Banner</Title>
          <Form onSubmit={handleFormSubmit}>
            <BoxInput>
              <Label>Subir Imagen (1280x600)</Label>
              <Input
                type='file'
                name='image'
                id='file'
                accept='.jpg, .jpeg, .png'
                onChange={(e) => setFile(e.target.files)}
                style={{ border: 'none' }}
              />
              <span style={{ fontSize: '12px' }}>
                *Peso de cada imagen (2mb maximo)
              </span>
            </BoxInput>
            <BoxButtons>
              <Button
                type='submit'
                disabled={file ? false : true}
                className='disabled'
                color='#fff'
                padding='10px 15px'
                margin='10px 0'
              >
                Agregar
              </Button>
            </BoxButtons>
          </Form>
          <Box>
            {img.map((item, index) => (
              <BoxImage key={index}>
                <img src={item.img} alt='' />
                <button
                  className='del'
                  onClick={() => handleDeleteImage(item.id)}
                >
                  <HighlightOff style={{ color: 'red' }} />
                </button>
                <button
                  className='edit'
                  onClick={() => handleChangeStatus(item.id, item.status)}
                >
                  {item.status ? 'Ocultar' : 'Mostrar'}
                </button>
              </BoxImage>
            ))}
          </Box>
        </Wrapper>
      </div>
    </>
  );
};

export default Banner;
