import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        products: [],
        quantity: 0,
        total: 0,
    },
    reducers: {
        // ------------------ AGREGAR PRODUCTO AL CARRITO ------------------
        addProduct: (state, action) => {
            state.quantity += 1;
            state.products.push(action.payload);
            state.total += action.payload.price * action.payload.quantity;
        },
        // ------------------ ELIMINAR PRODUCTO DEL CARRITO ------------------
        deleteProduct: (state, action) => {
            state.quantity -= 1;
            state.products.splice(state.products.findIndex((item) => item._id === action.payload.id),1);
            state.total -= action.payload.price * action.payload.quantity;
        },
        // ------------------ INCREMENTAR PRODUCTO DEL CARRITO ------------------
        updateQuantity: (state, action) => {
            state.products[
                state.products.findIndex((item) => item._id === action.payload.id)
            ] = action.payload.product;
            state.total += action.payload.price * action.payload.cant;
        },
        // ------------------ DECREMENTAR PRODUCTO DEL CARRITO ------------------
        updateQuantityDec: (state, action) => {
            state.products[
                state.products.findIndex((item) => item._id === action.payload.id)
            ] = action.payload.product;
            state.total -= action.payload.price * action.payload.cant;
        },
        // ------------------ LIMPIAR EL CARRITO ------------------
        limpiarCart: (state, action) => {
            state.products = [];
            state.quantity = 0;
            state.total = 0;
        },
    },
});

export const { addProduct, deleteProduct, updateQuantity, updateQuantityDec, limpiarCart } = cartSlice.actions;
export default cartSlice.reducer;