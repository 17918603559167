import React, { useEffect } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Autoplay, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import 'swiper/modules/effect-fade/effect-fade';
import "swiper/modules/navigation/navigation";
import "swiper/modules/pagination/pagination";

import "./carrousel.css";
import { useDispatch, useSelector } from "react-redux";
import { getBanner } from "api/apiCalls";

const Carrousel = () => {

  const images = useSelector((state) => state.banner.listImg);
  const dispatch = useDispatch();

  useEffect(() => {
    getBanner(dispatch);
  }, [dispatch]);

  // const [images, setImages] = useState([]);
  // const getImages = async () => {
  //   const res = await publicRequest.get("/banner");
  //   setImages(res.data);
  // };
  // useEffect(() => {
  //   getImages();
  // }, []);

  return (
    <>
    {/* { images.filter(item => item.status === true).length > 1 ?  */}
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        // className="mySwiper"
      >
        { images.filter(item => item.status === true).map((item, index) => (
          <SwiperSlide key={index} style={{ height: "400px" }}><img src={item.img} alt={item.img}/></SwiperSlide>
        ))}
      </Swiper>
        {/* <img src={images[0].img} alt="" style={{ margin: "70px 0 0", width: "100%", height: "500px", objectFit: "cover" }} /> */}
    </>
  );
}

export default Carrousel