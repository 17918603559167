import React, { useEffect, useState } from 'react';
import Topbar from 'components/administrador/topbar/Topbar';
import Sidebar from 'components/administrador/sidebar/Sidebar';
import { Button } from 'styles/GlobalComponents/ButtonStyles';
import {
  BoxButtons,
  BoxInput,
  Form,
  Input,
  Label,
  TextArea,
  Title,
  Wrapper,
} from 'styles/GlobalComponents/FormStyles';

import { publicRequest, userRequest } from 'config/clienteAxios';
import { alertSuccess } from 'components/alerts/SweetAlert';
import { Link } from 'react-router-dom';

const Configuracion = () => {
  const [data, setData] = useState({
    name: '',
    description: '',
    tel: '',
    phone: '',
    email: '',
    address: '',
    facebook: '',
    instagram: '',
    costos: [],
  });

  const [cost, setCost] = useState({
    envio: '',
    adicional: '',
    monto: '',
  });

  const [show, setShow] = useState(false);

  const getInfo = async () => {
    try {
      const res = await publicRequest.get('/infotienda');
      console.log({ res });
      const info = res.data?.info[0];
      setData(info);
      setCost(info.costs ? info.costs : null);
      if (res.data) {
        setShow(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputChange = (e) => {
    setData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  // const handleCostosChange = (e) => {
  //   setCost((prev) => {
  //     return { ...prev, [e.target.name]: e.target.value };
  //   });
  // };
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const producto = { ...data, costos: cost };

    try {
      const updateInfo = async () => {
        const res = await userRequest.put(`/infotienda/${data.id}`, producto);
        if (res.data.success) {
          alertSuccess(res.data.msg);
        }
      };
      updateInfo();
    } catch (error) {}
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Wrapper>
          <Title>Datos Tienda</Title>
          {data === undefined && (
            <Form>
              <Link to='/nuevaConfiguracion'>
                <BoxButtons>
                  <Button
                    type='submit'
                    color='#fff'
                    padding='10px 15px'
                    margin='10px 0'
                  >
                    Agregar Informacion
                  </Button>
                </BoxButtons>
              </Link>
            </Form>
          )}
          {show && (
            <Form>
              <BoxInput>
                <Label>Nombre Tienda</Label>
                <Input
                  name='name'
                  type='text'
                  value={data?.name}
                  onChange={handleInputChange}
                />
              </BoxInput>
              <BoxInput>
                <Label>Descripcion</Label>
                <TextArea
                  name='description'
                  cols='30'
                  rows='8'
                  value={data?.description || ''}
                  onChange={handleInputChange}
                ></TextArea>
              </BoxInput>
              <BoxInput>
                <Label>Teléfono</Label>
                <Input
                  name='tel'
                  type='tel'
                  value={data?.tel}
                  onChange={handleInputChange}
                />
              </BoxInput>
              <BoxInput>
                <Label>Teléfono Movil</Label>
                <Input
                  name='phone'
                  type='tel'
                  value={data?.phone}
                  onChange={handleInputChange}
                />
              </BoxInput>
              <BoxInput>
                <Label>Email</Label>
                <Input
                  name='email'
                  type='email'
                  value={data?.email}
                  onChange={handleInputChange}
                />
              </BoxInput>
              <BoxInput>
                <Label>Dirección</Label>
                <Input
                  name='address'
                  type='text'
                  value={data?.address}
                  onChange={handleInputChange}
                />
              </BoxInput>
              <BoxInput>
                <Label>Facebook</Label>
                <Input
                  name='facebook'
                  type='text'
                  value={data?.facebook}
                  onChange={handleInputChange}
                />
              </BoxInput>
              <BoxInput>
                <Label>Instagram</Label>
                <Input
                  name='instagram'
                  type='text'
                  value={data?.instagram}
                  onChange={handleInputChange}
                />
              </BoxInput>

              {/* <BoxInput>
              <Label  style={{ marginTop: "10px"}}>Costos</Label>
            </BoxInput>
            <BoxInput>
              <Label>Envio</Label>
              <Input
                name="envio"
                type="number"
                value={cost?.envio}
                min={0}
                onChange={handleCostosChange}
              />
            </BoxInput>
            <BoxInput>
              <Label>Adicional</Label>
              <Input
                name="adicional"
                type="number"
                value={cost?.adicional}
                min={0}
                onChange={handleCostosChange}
              />
            </BoxInput>
            <BoxInput>
              <Label>Monto</Label>
              <Input
                name="monto"
                type="number"
                value={cost?.monto}
                min={0}
                onChange={handleCostosChange}
              />
            </BoxInput> */}

              <BoxButtons>
                <Button
                  color='#fff'
                  padding='10px 15px'
                  margin='10px 10px 0 0'
                  onClick={handleFormSubmit}
                >
                  Guardar
                </Button>
              </BoxButtons>
            </Form>
          )}
        </Wrapper>
      </div>
    </>
  );
};

export default Configuracion;
